const state = {
  loggedIn: false,
  userName: {
    fullName: null,
    initials: null,
  },
  boekinsStatus: 2,
  numberofguests: null,
  changedName: false,
  teamName: '',
  paymentsStatus: false,
  hideInstallMessage: false,
  lastFetchedNotification: null,
  newNoti: false,
};

export default state;
