import { createApolloProvider } from '@vue/apollo-option';

import { apolloClient, apolloStoryBlokClient } from '@/apollo/client';

export const apolloProvider = createApolloProvider({
  clients: {
    storyBlok: apolloStoryBlokClient,
  },
  defaultClient: apolloClient,
});
