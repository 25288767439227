<template>
  <div
    class="md:min-h-full w-screen md:max-w-full min-h-screen max-w-screen bg-body flex flex-col"
  >
    <ReservationTopSection
      class="py-11 bg-primary flex justify-center items-center"
      style="height: 15vh"
    >
      <div class="text-center">
        <h2 class="text-secondaryHeading text-4xl">Reserveren 2023</h2>
      </div>
    </ReservationTopSection>
    <Card class="mt-4">
      Nu al zin in volgend jaar?! Geef nu alvast aan of jij bij Teamweekender
      2023 aanwezig bent. We houden alvast een plekje voor jullie vrij. Wij
      zorgen gegarandeerd voor een nog beter weekend! &#127881;
    </Card>
    <Card class="mt-1">
      <form
        ref="form"
        class="w-10/12 mx-auto mt-5 pb-4"
        @submit.prevent="validate()"
      >
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              Voornaam
            </label>
            <basic-input
              v-model="name"
              :_uid="'name'"
              :type="'text'"
              :placeholder="'Naam'"
              :hasError="validation.firstError('name') ? true : false"
              :errMsg="validation.firstError('name')"
            />
          </div>
          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Achternaam
            </label>
            <basic-input
              v-model="surename"
              :_uid="'surename'"
              :type="'text'"
              :placeholder="'Achternaam'"
              :hasError="validation.firstError('surename') ? true : false"
              :errMsg="validation.firstError('surename')"
            />
          </div>

          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-team-name"
            >
              Teamname
            </label>
            <basic-input
              v-model="teamname"
              :_uid="'teamname'"
              :type="'text'"
              :placeholder="'Teamname'"
              :hasError="validation.firstError('teamname') ? true : false"
              :errMsg="validation.firstError('teamname')"
            />
          </div>
          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-email"
            >
              E-mailadres
            </label>
            <basic-input
              v-model="email"
              :_uid="'email'"
              :type="'text'"
              :placeholder="'voorbeeld@email.nl'"
              :hasError="validation.firstError('email') ? true : false"
              :errMsg="validation.firstError('email')"
            />
          </div>

          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-phone"
            >
              Telefoonnummer
            </label>
            <basic-input
              v-model="phone"
              :_uid="'phone'"
              :type="'tel'"
              :placeholder="'0612345678'"
              :hasError="validation.firstError('phone') ? true : false"
              :errMsg="validation.firstError('phone')"
            />
          </div>

          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-comment"
            >
              Opmerking
            </label>
            <basic-input
              v-model="comment"
              :_uid="'comment'"
              :type="'text'"
              :placeholder="'Extra info'"
            />
          </div>
          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-voucherGuid"
            >
              voucherGuid
            </label>
            <basic-input
              v-model="voucherGuid"
              :_uid="'voucherGuid'"
              :type="'text'"
              :placeholder="'Voucher code'"
              :hasError="validation.firstError('voucherGuid') ? true : false"
              :errMsg="validation.firstError('voucherGuid')"
            />
          </div>

          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-genderOfTeam"
            >
              Samenstelling team
            </label>
            <select
              class="block appearance-none w-full bg-white border-2 border-btnBorder px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              id="grid-genderOfTeam"
              v-model="genderOfTeam"
            >
              <option>Mannen</option>
              <option>Vrouwen</option>
              <option>Mix</option>
            </select>
            <div style="height: 24px">
              <span
                class="text-red-400"
                v-if="validation.firstError('genderOfTeam')"
              >
                {{ validation.firstError('genderOfTeam') }}
              </span>
            </div>
          </div>

          <div class="w-full px-3 mb-2">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-nog"
            >
              Aantal mensen
            </label>
            <basic-input
              v-model="numberofguests"
              :_uid="'numberofguests'"
              :type="'number'"
              :placeholder="'11'"
              :hasError="validation.firstError('numberofguests') ? true : false"
              :errMsg="validation.firstError('numberofguests')"
            />
          </div>
        </div>
        <button class="w-full" type="submit">
          <PrimaryButton class="w-full inline-block px-9">
            <div class="py-2 text-aditionalTxt text-lg">Reserveren</div>
          </PrimaryButton>
        </button>
      </form>
    </Card>
    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">{{ modalTitle }}</h2>
        <p class="mb-6">
          {{ msg }}
        </p>
        <div class="flex justify-center w-10/12 mx-auto">
          <PrimaryButton @click="nextPage()"> Sluiten </PrimaryButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createReservation } from '@/graphql/reservations/mutations.js';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import SimpleVueValidator from 'simple-vue3-validator';
import BasicInput from '@/components/inputs/BasicInput.vue';
import Modal from '@/components/partials/Modal';
import Card from '@/components/partials/Card.vue';

export default {
  components: {
    BasicInput,
    PrimaryButton,
    Modal,
    Card,
  },
  data() {
    return {
      sending: false,
      genderOfTeam: null,
      teamname: null,
      name: null,
      surename: null,
      email: null,
      phone: null,
      comment: '',
      voucherGuid: '',
      numberofguests: null,
      showModal: false,
      msg: '',
      modalTitle: '',
      resNr: null,
      resStatus: null,
    };
  },
  validators: {
    name(value) {
      return SimpleVueValidator.Validator.value(value).required('verplicht');
    },
    surename(value) {
      return SimpleVueValidator.Validator.value(value).required('verplicht');
    },
    email(value) {
      return SimpleVueValidator.Validator.value(value)
        .required('verplicht')
        .email('Geen geldig email adres');
    },
    phone(value) {
      return SimpleVueValidator.Validator.value(value)
        .required('verplicht')
        .digit('Alleen getallen')
        .length(10, 'Er missen getallen');
    },
    teamname(value) {
      return SimpleVueValidator.Validator.value(value).required('verplicht');
    },
    numberofguests(value) {
      return SimpleVueValidator.Validator.value(value).required('verplicht');
    },
    genderOfTeam(value) {
      return SimpleVueValidator.Validator.value(value).required('verplicht');
    },
  },
  methods: {
    validate() {
      const self = this;
      this.$validate().then(function (success) {
        if (success) {
          self.submitRes();
        }
      });
    },
    async submitRes() {
      if (this.sending) return;
      this.sending = true;

      const input = {
        genderOfTeam: this.genderOfTeam,
        teamname: this.teamname,
        name: this.name,
        surename: this.surename,
        email: this.email,
        phone: this.phone,
        comment: this.comment,
        voucherGuid: this.voucherGuid,
        numberofguests: parseInt(this.numberofguests),
        weekendID: 9,
      };

      for (const key in input) {
        if (input[key] == null) {
          alert('missing fields');
          return;
        }
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: createReservation,
          variables: {
            input,
          },
        });

        this.resStatus = res?.data?.createReservation?.status;

        if (this.resStatus == 200) {
          this.resNr = res?.data?.createReservation?.reservationnumber;
          this.modalTitle = 'Gelukt!';
          this.msg =
            'Je ontvangt binnen 5 minuten een bevestigingsmail. Via deze mail kan je je account activeren als je dat nog niet hebt gedaan.';
          this.showModal = true;
          this.sending = false;
        } else {
          this.modalTitle = 'Er ging wat mis!';
          this.msg = res?.data?.createReservation?.statusMsg;
          this.showModal = true;
          this.sending = false;
        }
      } catch (e) {
        console.error(e);
        this.modalTitle = 'Er ging wat mis!';
        this.msg = e;
        this.showModal = true;
      }
    },
    nextPage() {
      if (this.resStatus === 200) {
        window.location.href = `${process.env.VUE_APP_BASE_URL}/ResConfirm/${this.resNr}`;
      } else {
        this.showModal = false;
      }
    },
  },
};
</script>
