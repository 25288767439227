<template>
  <div
    class="md:min-h-full w-screen md:max-w-full min-h-screen max-w-screen bg-body flex flex-col"
  >
    <ReservationTopSection class="py-11" style="height: 15vh">
      <div class="text-center">
        <h2 class="text-secondaryHeading text-4xl">Programma</h2>
      </div>
    </ReservationTopSection>
    <div class="flex py-4 px-2 justify-between" style="height: 70px">
      <div class="grow text-center px-2">
        <div
          @click="setDay(1)"
          class="border-2 border-primary text-2xl"
          :class="currDay == 1 ? 'bg-btnBg text-white' : ''"
        >
          Vrijdag
        </div>
      </div>
      <div class="grow text-center px-2">
        <div
          @click="setDay(2)"
          class="border-2 border-primary text-2xl"
          :class="currDay == 2 ? 'bg-btnBg text-white' : ''"
        >
          zaterdag
        </div>
      </div>
    </div>
    <div class="px-4 overflow-auto" style="height: calc(85vh - 140px)">
      <transition name="fadeQuick" mode="out-in">
        <p v-if="currProgrampostItems.length < 1" class="text-center">
          Het programma voor deze dag maken we op een later moment bekend.
          <span v-if="!iOS">Zet je notificaties aan of houd</span>
          <span v-else>Houd</span> je dashboard goed in de gaten.
        </p>
        <div v-else :key="currProgrampostItems[0].id">
          <ProgrammaItem
            v-for="ProgrampostItem in currProgrampostItems"
            :programpostItem="ProgrampostItem"
            :key="ProgrampostItem.id"
            :currDay="currDay"
            :disableBtn="activeActivaties.includes(ProgrampostItem.id)"
            :attendeesAmount="itemsAmount[ProgrampostItem.id]"
            @update-amounts="refetchTableItemsAmounts"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ReservationTopSection from '@/components/ReservationTopSection.vue';
import ProgrammaItem from '@/components/ProgrammaItem.vue';
import {
  TableItems,
  TableItemsAmounts,
  ActiveTableItems,
} from '@/graphql/events/query.js';

export default {
  components: {
    ReservationTopSection,
    ProgrammaItem,
  },
  data() {
    return {
      page: 1,
      currDay: 1,
      currProgrampostItems: [],
      itemsAmount: {},
      activeActivaties: [],
    };
  },
  apollo: {
    ProgrampostItems() {
      return {
        query: TableItems,
        client: 'storyBlok',
        result({ data }) {
          let currDayArr = [];

          data?.ProgrampostItems?.items.filter((obj) => {
            if (obj?.content?.day == this.currDay) {
              currDayArr.push(obj);
            }
          });

          this.currProgrampostItems = currDayArr?.slice().sort(function (a, b) {
            return (
              new Date(a.content?.startTime.replace(/\s/g, 'T')) -
              new Date(b.content?.startTime.replace(/\s/g, 'T'))
            );
          });
        },
      };
    },
    TableItemsAmounts() {
      return {
        query: TableItemsAmounts,
        variables() {
          return {
            day: this.currDay,
          };
        },
        pollInterval: 5000,
        result({ data }) {
          for (let i = 0; i < data.TableItemsAmounts.length; i++) {
            this.itemsAmount[data.TableItemsAmounts[i].storyblokID] =
              data.TableItemsAmounts[i].attendees_amount;
          }
        },
      };
    },
    ActiveTableItems() {
      return {
        query: ActiveTableItems,
        result({ data }) {
          this.activeActivaties = [];
          for (let i = 0; i < data.ActiveTableItems.length; i++) {
            const id = data.ActiveTableItems[i].eventID;
            this.activeActivaties.push(id);
          }
        },
      };
    },
  },
  methods: {
    setDay(day) {
      this.currProgrampostItems = [];
      this.currDay = day;
      this.$apollo.queries.ProgrampostItems.refetch();
    },
    refetchTableItemsAmounts(e) {
      this.activeActivaties.push(e);
      this.$apollo.queries.TableItemsAmounts.refetch();
    },
  },
  computed: {
    iOS() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
  },
  watch: {
    TableItemsAmounts(data) {
      for (let i = 0; i < data.length; i++) {
        this.itemsAmount[data[i].storyblokID] = data[i].attendees_amount;
      }
    },
  },
};
</script>
