<template>
  <main>
    <Header />
    <ReservationTopSection style="height: calc(235px - 58px)">
      <div
        @click="$router.go('-1')"
        v-if="isIos"
        class="absolute top-5 left-6 text-xl z-50"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </div>
      <h1 class="text-2xl pb-1">
        {{ teamName ? teamName : reservationUnAuth?.team?.name }}
      </h1>
      <p class="text-lg">
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'people-group']"
        />
        {{ reservationUnAuth?.numberofguests }} personen
      </p>
      <p class="text-lg" v-if="!reservationUnAuth?.status">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'ban']" />
        booking geannuleerd
      </p>
      <p class="text-lg" v-else-if="reservationUnAuth?.status === 2">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'hourglass']" />
        booking in behandeling
      </p>
      <p class="text-lg" v-else>
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'circle-check']"
        />
        boeking bevestigd
      </p>
    </ReservationTopSection>
    <div class="pt-3">
      <Card>
        <div class="mx-auto flex flex-col py-4">
          <div
            :class="
              voucherPayment ? 'grid grid-cols-3 gap-0' : 'flex justify-center'
            "
          >
            <PaymentItem
              :class="!voucherPayment ? 'mx-2' : ''"
              :amount="totalDepositAmount"
              :payedAmount="payedObj.deposit.amount"
              :label="'Aanbetaling'"
            />

            <PaymentItem
              :class="!voucherPayment ? 'mx-2' : 'mx-1'"
              :amount="totalRemnantAmount"
              :payedAmount="payedObj.remnant.amount"
              :label="'Restant'"
            />

            <PaymentItem
              v-if="voucherPayment"
              :amount="voucherInitialVal"
              :payedAmount="voucherVal"
              :label="'voucher'"
            />
          </div>
        </div>
      </Card>
      <Card class="h-full flex flex-col justify-between" style="height: 80%">
        <div class="mb-4">
          <Title class="text-xl mb-2">
            {{ paymentType == 'normal' ? 'Restant bedrag' : 'Aanbetaling' }}
          </Title>
          <p class="mb-4">
            Betaal hier alleen jouw deel. Met de deel-button kan jij de
            betaallink met je teamgenoten delen zodat zij ook hun deel kunnen
            betalen.
          </p>
        </div>

        <div>
          <div
            class="
              transition
              duration-500
              transition-max-height
              overflow-hidden
              mb-3
              text-center
            "
          >
            <b class="block mb-2">
              Te betalen bedrag via iDEAL: €
              {{ formattedCurrency(amountToPay) }},-
            </b>
            <div class="grid grid-cols-2 gap-4">
              <PrimaryButton @click="idealPayment">
                <div class="text-aditionalTxt text-xl">Betaal jouw deel</div>
              </PrimaryButton>
              <PrimaryButton @click="SharePaymentRequest">
                <span class="flex items-center justify-between">
                  Delen
                  <font-awesome-icon
                    class="ml-2 pb-1"
                    size="lg"
                    :icon="['fab', 'whatsapp']"
                  />
                </span>
              </PrimaryButton>
            </div>
          </div>

          <!-- <p class="text-sm">
            Let op: Nadat een deel van het restant is betaald, kan er niet meer
            worden bijgeboekt.
          </p> -->
        </div>
      </Card>
    </div>
  </main>
</template>

<script setup>
import formattedCurrency from '@/formattedCurrency.js';
</script>

<script>
import Header from '@/components/partials/Header.vue';
import ReservationTopSection from '@/components/ReservationTopSection.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Title from '@/components/partials/Title.vue';
import Card from '@/components/partials/Card.vue';
import PaymentItem from '@/components/PaymentItem.vue';

import { getUnAuthReservation } from '@/graphql/reservations/query.js';
import { createPayment } from '@/graphql/payments/mutations';
import { getPaidAmount } from '@/getPaidAmount';

export default {
  name: 'Todo',

  components: {
    Header,
    ReservationTopSection,
    PrimaryButton,
    Title,
    Card,
    PaymentItem,
  },

  data() {
    return {
      openIdeal: false,
      paymentType: this.$route.params.type,
      remnantAmount: null,
      depositAmount: null,
      voucherPayment: false,
      voucherVal: null,
      voucherInitialVal: null,
      teamName: false,
    };
  },
  apollo: {
    reservationUnAuth() {
      return {
        query: getUnAuthReservation,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        networkPolicy: 'cache-and-network',
        result({ data }) {
          this.depositAmount = data?.reservationUnAuth.deposit_fee.amount;
          this.remnantAmount =
            data?.reservationUnAuth.remnant_fee.amount - this.depositAmount;

          this.$store.commit(
            'setBoekinsStatus',
            data?.reservationUnAuth?.status
          );
          this.$store.commit(
            'setChangedName',
            data?.reservationUnAuth?.team?.changedName
          );
          this.teamName = data?.reservationUnAuth?.team?.name;
          this.$store.commit(
            'setTeamName',
            data?.reservationUnAuth?.team?.name
          );
          this.$store.commit(
            'setPaymentStatus',
            data?.reservationUnAuth?.payment_status
          );
        },
      };
    },
  },
  methods: {
    async idealPayment() {
      try {
        const res = await this.$apollo.mutate({
          mutation: createPayment,
          variables: {
            input: {
              reservationID: this.reservationUnAuth.id,
              splitpayment: 1,
            },
          },
        });
        if (res.data.createPayment.paymentUrl) {
          window.location.href = res.data.createPayment.paymentUrl;
        }
      } catch (e) {
        console.error(e);
      }
    },

    SharePaymentRequest() {
      const payUrl = `${process.env.VUE_APP_BASE_URL}/betaalverzoek/${this.toPaytype}/${this.$route.params.reservationnumber}`;
      let msg;

      if (this.toPaytype == 'deposit') {
        msg = `Ik heb ons ingeschreven voor nu al ons mooiste teamweekend ooit via teamweekender.nl. Kan iedereen voor zichzelf de aanbetaling van 3 tientjes doen via: ${payUrl}`;
      } else {
        msg = `Het is tijd om het restant voor ons teamweekend te betalen. Kunnen jullie dit svp allemaal zsm doen? Link: ${payUrl}`;
      }
      window.open(`https://api.whatsapp.com/send?text=${msg}`, '_blank');
    },
  },

  computed: {
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },

    payedObj() {
      return getPaidAmount(this.reservationUnAuth);
    },

    totalDepositAmount() {
      return (
        this.reservationUnAuth?.numberofguests *
        this.reservationUnAuth?.deposit_fee.amount
      );
    },

    totalRemnantAmount() {
      return (
        this.reservationUnAuth?.numberofguests *
          this.reservationUnAuth?.remnant_fee.amount -
        this.totalDepositAmount
      );
    },

    toPaytype() {
      if (
        this.payedObj.deposit.amount < this.totalDepositAmount ||
        this.reservationUnAuth?.status == 2
      ) {
        return 'deposit';
      } else {
        return 'normal';
      }
    },

    totalNOG() {
      return this.reservationUnAuth?.numberofguests + this.toAdd;
    },

    inputClasses() {
      return 'w-full bg-PreFocustInput outline-none transition-all duration-700 placeholder-white rounded-full p-4 mb-6 outline-none pr-3';
    },

    amountToPay() {
      if (this.paymentType == 'normal') {
        return this.remnantAmount;
      } else {
        return this.depositAmount;
      }
    },
  },
};
</script>
