import { createApp } from 'vue';
import App from './App.vue';

import { apolloProvider } from '@/apollo/provider.js';
import SimpleVueValidator from 'simple-vue3-validator';

// Store
import store from './store';
App.store = store;

// import './registerServiceWorker';
window.OneSignal = window.OneSignal || [];
window.OneSignal.push(() => {
  window.OneSignal.init({
    appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
    allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
  });
});

import router from './router';
import 'tailwindcss/tailwind.css';

// Set date library
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import 'dayjs/locale/de';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faQrcode,
  faArrowsRotate,
  faCheck,
  faCircleCheck,
  faPeopleGroup,
  faBan,
  faHourglass,
  faUserSlash,
  faXmark,
  faChevronLeft,
  faHouseChimney,
  faEuroSign,
  faEnvelope,
  faCalendar,
  faComment,
  faPerson,
  faPlus,
  faPhotoFilm,
} from '@fortawesome/free-solid-svg-icons';

import { faCommentExclamation } from '@fortawesome/pro-light-svg-icons';
import {
  faGripVertical,
  faBorderCenterV,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faQrcode,
  faArrowsRotate,
  faWhatsapp,
  faCheck,
  faCircleCheck,
  faPeopleGroup,
  faBan,
  faHourglass,
  faUserSlash,
  faXmark,
  faChevronLeft,
  faHouseChimney,
  faEuroSign,
  faCommentExclamation,
  faGripVertical,
  faBorderCenterV,
  faComment,
  faEnvelope,
  faCalendar,
  faPerson,
  faPlus,
  faPhotoFilm
);

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(apolloProvider)
  .use(SimpleVueValidator);

Object.defineProperties(app.config.globalProperties, {
  $date: { get: () => dayjs },
});

app.mount('#app');
