<template>
  <Header />
  <div
    class="bg-primary flex items-center justify-center h-screen"
    style="height: calc(100vh - 58px)"
  >
    <Card
      ref="registerContainer"
      class="w-10/12 max-w-screen-sm overflow-hidden flex justify-center max-h-full overflow-y-auto"
      :style="{ minHeight: initContainerHeight + 'px' }"
    >
      <div class="w-full sm:w-10/12 flex flex-col h-full">
        <div class="mb-4">
          <h2 class="text-primaryHeading text-3xl text-center mb-3 font-bold">
            REGISTREREN
          </h2>
          <p class="text-lg mb-3">
            Welkom in jouw eigen Teamweekender dashboard. Hier regel je alles.
            Bevestig hieronder je gegevens en krijg toegang.<br />
            <span v-if="TeamGuid">
              Door te registreren neem je deel aan het team waar voor bent
              uitgenodigd.
            </span>
          </p>
          <p class="text-lg mb-3">
            Heb je al een account?
            <a class="text-link" :href="loginUrl">Klik hier!</a>
          </p>
        </div>
        <form
          ref="registerForm"
          class="grow"
          @submit.prevent="registerUser($event)"
        >
          <transition name="bounce" mode="out-in">
            <div
              v-if="formStatus == 'password'"
              class="formSection flex flex-col justify-between h-full"
            >
              <div>
                <basic-input
                  v-model="input.password"
                  :_uid="'1'"
                  :placeholder="'Wachtwoord'"
                  :required="true"
                  :type="'password'"
                  :hasError="
                    validation.firstError('input.password') ? true : false
                  "
                  :errMsg="validation.firstError('input.password')"
                />
                <basic-input
                  v-model="passwordReference"
                  :_uid="'2'"
                  :placeholder="'Herhaal wachtwoord'"
                  :required="true"
                  :type="'password'"
                  :hasError="
                    validation.firstError('passwordReference') ? true : false
                  "
                  :errMsg="validation.firstError('passwordReference')"
                />
              </div>
              <button @click="validate">
                <PrimaryButton class="inline-block px-9 shadow-btn">
                  Registreren
                </PrimaryButton>
              </button>
            </div>
            <div v-else class="grow formSection flexflex">
              <div>
                <basic-input
                  v-model="input.name"
                  :_uid="'3'"
                  :placeholder="'Voornaam'"
                  :required="true"
                  :hasError="validation.firstError('input.name') ? true : false"
                  :errMsg="validation.firstError('input.name')"
                />
                <basic-input
                  v-model="input.surename"
                  :_uid="'4'"
                  :placeholder="'Achternaam'"
                  :required="true"
                  :hasError="
                    validation.firstError('input.surename') ? true : false
                  "
                  :errMsg="validation.firstError('input.surename')"
                />
                <basic-input
                  v-model="input.email"
                  :inputClass="$route.params.jwt ? 'bg-btnDisabledBg' : ''"
                  :_uid="'5'"
                  :placeholder="'Email'"
                  :required="true"
                  :type="'email'"
                  :disabled="$route.params.jwt ? true : false"
                  :hasError="
                    validation.firstError('input.email') ? true : false
                  "
                  :errMsg="validation.firstError('input.email')"
                />
                <basic-input
                  v-model="input.phone"
                  :_uid="'6'"
                  :placeholder="'Telefoonnummer'"
                  :required="true"
                  :type="'tel'"
                  :hasError="
                    validation.firstError('input.phone') ? true : false
                  "
                  :errMsg="validation.firstError('input.phone')"
                />
              </div>
              <PrimaryButton
                class="inline-block px-9 shadow-btn"
                @click="validate"
              >
                <div class="py-1 text-aditionalTxt text-3xl font-bold">
                  Volgende
                </div>
              </PrimaryButton>
            </div>
          </transition>
        </form>
      </div>
    </Card>
  </div>
  <Modal class="flex align-center" v-if="showModal">
    <div class="w-11/12 mx-auto flex justify-center flex-col">
      <h2 class="text-black text-2xl mb-3 font-bold">
        {{ status === 200 ? 'Gelukt' : 'Er ging wat mis' }}
      </h2>
      <p class="mb-6">
        {{ modalMsg }}
      </p>
      <div class="flex justify-center w-10/12 mx-auto">
        <PrimaryButton @click="$router.push({ name: 'Login' })">
          Sluiten
        </PrimaryButton>
      </div>
    </div>
  </Modal>
</template>

<script>
// Graphql
import { RegisterUser } from '@/graphql/user/mutations.js';
import { myUser } from '@/graphql/user/query.js';
// components
import Header from '@/components/partials/Header';
import BasicInput from '@/components/inputs/BasicInput.vue';
import Card from '@/components/partials/Card.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Modal from '@/components/partials/Modal.vue';

import SimpleVueValidator from 'simple-vue3-validator';

export default {
  name: 'Register',

  components: {
    Header,
    BasicInput,
    Card,
    PrimaryButton,
    Modal,
  },

  data() {
    return {
      formStatus: 'first',
      initContainerHeight: '0px',
      input: {
        name: '',
        surename: '',
        email: '',
        phone: '',
        password: '',
      },
      passwordReference: '',
      TeamGuid: null,
      loginUrl: `${process.env.VUE_APP_BASE_URL}/login`,
      modalMsg: '',
      status: null,
      showModal: false,
    };
  },

  watch: {
    input: {
      deep: true,
      handler() {
        if (this.error) this.error = false;
      },
    },
  },

  apollo: {
    myUser() {
      return {
        query: myUser,
        variables() {
          return {
            jwt: this.$route.params.jwt,
          };
        },
        skip: () => !this.$route.params.jwt,
        result({ data }) {
          this.input.name = data.myUser.name;
          this.input.surename = data.myUser.surename;
          this.input.phone = data.myUser.phone;
          this.input.email = data.myUser.email;
          this.$store.commit('setUserName', data?.myUser);
        },
      };
    },
  },

  validators: {
    'input.name'(value) {
      return SimpleVueValidator.Validator.value(value).required();
    },
    'input.surename'(value) {
      return SimpleVueValidator.Validator.value(value).required();
    },
    'input.phone'(value) {
      return SimpleVueValidator.Validator.value(value)
        .required()
        .minLength(10, 'Minimaal 10 getallen')
        .regex(
          /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
          'Telefoonnummer is niet geldig'
        );
    },
    'input.email'(value) {
      return SimpleVueValidator.Validator.value(value)
        .required()
        .email('Geen geldig email adres');
    },
    'input.password'(value) {
      if (this.formStatus === 'password') {
        return SimpleVueValidator.Validator.value(value)
          .required()
          .minLength(6, 'Het wachtwoord is te kort');
      }
    },
    'passwordReference, input.password': function (
      passwordReference,
      password
    ) {
      if (this.formStatus === 'password') {
        if (this.submitted || this.validation.isTouched('passwordReference')) {
          return SimpleVueValidator.Validator.value(passwordReference)
            .required()
            .match(password, 'De wachtwoorden komen niet overeen');
        }
      }
    },
  },

  mounted() {
    this.initContainerHeight = this.$refs.registerContainer.$el.clientHeight;
    if (typeof this.$route.params.TeamGuid != 'undefined') {
      this.TeamGuid = this.$route.params.TeamGuid;
      this.loginUrl = `${process.env.VUE_APP_BASE_URL}/invite/login/${this.TeamGuid}`;
    }
  },

  methods: {
    validate() {
      const self = this;
      this.$validate().then(function (success) {
        if (success) {
          if (self.formStatus === 'password') {
            self.$refs.registerForm.$el.submit();
          } else {
            self.setFormStatus('password');
          }
        }
      });
    },

    setFormStatus(status) {
      this.formStatus = status;
    },

    async registerUser(e) {
      const { input } = this;
      const { password } = input;

      if (typeof this.$route.params.TeamGuid != 'undefined') {
        input.TeamGuid = this.TeamGuid;
      }

      if (typeof this.$route.params.jwt != 'undefined') {
        input.jwt = this.$route.params.jwt;
      }
      e.preventDefault();

      if (this.passwordReference !== password) return;
      try {
        const res = await this.$apollo.mutate({
          mutation: RegisterUser,
          variables: {
            input,
          },
        });

        const response = res?.data?.RegisterUser;
        this.status = response?.status;

        if (input.jwt) {
          if (this.status === 200) {
            this.modalMsg = 'Je kan nu inloggen.';
          } else {
            this.modalMsg = response?.statusMsg;
          }
          this.showModal = true;
        } else {
          this.modalMsg = response?.statusMsg;
          this.showModal = true;
        }
      } catch (e) {
        console.error(e);
        console.error('er ging iets mis. Probeer het later opnieuw');
      }
    },
  },
};
</script>

<style>
.grow {
  flex-grow: 1;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
