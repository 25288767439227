<template>
  <div class="grow text-center" style="max-width: 140px">
    <div class="py-2 text-center bg-primary">
      <span class="text-md text-white font-bold">
        &euro;{{ formattedCurrency(payedAmount) }} / &euro;{{
          formattedCurrency(amount)
        }}
      </span>
    </div>
    <span class="text-black text-sm break-words"> {{ label }} </span>
  </div>
</template>

<script setup>
import formattedCurrency from '@/formattedCurrency.js';
</script>

<script>
export default {
  props: {
    amount: {
      type: Number,
    },
    payedAmount: {
      type: Number,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
