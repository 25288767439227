import gql from 'graphql-tag';

export const getEvents = gql`
  query Events {
    events {
      weekendID
      locationName
      mapsUrl
      reserveUponApproval
      maxReserveDateTime
      startsAt
      endsAt
    }
  }
`;

export const notifications = gql`
  query ($per_page: Int!, $page: Int!) {
    NotificationItems(
      starts_with: "notifications/"
      per_page: $per_page
      page: $page
    ) {
      items {
        id
        name
        first_published_at
        content {
          title
          icon {
            filename
          }
          intro
          long_text
        }
      }
      total
    }
  }
`;

export const newNotifications = gql`
  query ($per_page: Int!, $page: Int!) {
    NotificationItems(
      starts_with: "notifications/"
      per_page: $per_page
      page: $page
    ) {
      items {
        id
      }
    }
  }
`;

export const TableItems = gql`
  query {
    ProgrampostItems(starts_with: "programma/") {
      items {
        id
        name
        first_published_at
        content {
          title
          image
          intro
          day
          startTime
          endTime
          type
        }
      }
      total
    }
  }
`;

export const TableItemsAmounts = gql`
  query ($day: Int!) {
    TableItemsAmounts(day: $day) {
      storyblokID
      attendees_amount
    }
  }
`;

export const ActiveTableItems = gql`
  query {
    ActiveTableItems {
      eventID
    }
  }
`;
