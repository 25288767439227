<template>
  <div
    :style="{ 'background-color': programpostItem?.content?.type }"
    class="bg-white p-3 border-b-2 mb-2"
  >
    <div class="flex flex-col">
      <div class="w-full text-white pb-1 pb-3">
        <h2 class="text-2xl pb-2 flex justify-between">
          <span>
            {{ programpostItem?.content?.title }}
          </span>
          <span class="ml-2 whitespace-nowrap">
            {{
              `${$date(programpostItem?.content?.startTime).format('HH:mm')}
              -
              ${$date(programpostItem?.content?.endTime).format('HH:mm')}`
            }}
          </span>
        </h2>
        <p class="pb-2" v-html="richtext"></p>
        <div v-if="programpostItem?.content?.image">
          <img class="w-full" :src="programpostItem?.content?.image" alt="" />
        </div>
      </div>
      <div class="flex justify-end items-center">
        <div class="mr-6 text-white flex items-center">
          <font-awesome-icon class="text-xl mr-1" :icon="['fas', 'person']" />
          <div
            ref="score"
            class="score mt-1"
            data-added="0"
            :data-total="attendeesAmount"
          ></div>
          <span class="mt-1">+</span>
        </div>
        <div
          :class="disableBtn ? 'bg-btnDisabledBg' : 'bg-btnBg'"
          class="w-1/2 py-2 flex justify-center text-white"
          @click="addUserToActivatie(programpostItem?.id)"
        >
          <font-awesome-icon class="text-xl mr-2" :icon="['fas', 'plus']" />
          Ik ben erbij!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoryblokClient from 'storyblok-js-client';

import { addUserToActivatie } from '@/graphql/events/mutations.js';

export default {
  props: {
    programpostItem: {
      default: null,
    },
    currDay: {
      default: 1,
    },
    attendeesAmount: {
      default: 0,
    },
    disableBtn: {
      default: false,
    },
  },
  watch: {
    attendeesAmount: {
      handler(oldVal, newVal) {
        this.addToAttendeesAmount(oldVal - newVal);
      },
    },
  },
  computed: {
    richtext() {
      let Storyblok = new StoryblokClient({
        accessToken: process.env.VUE_APP_STORYBLOK,
      });

      return Storyblok.richTextResolver.render(
        this.programpostItem?.content?.intro
      );
    },
  },
  methods: {
    addToAttendeesAmount(amount) {
      const score = this.$refs.score;
      let count = this.attendeesAmount;

      score.dataset.added = '+' + amount;
      score.dataset.total = count += amount;
      if (count) score.classList.add('animate');

      setTimeout(() => {
        score.classList.remove('animate');
      }, 500);
    },
    async addUserToActivatie(id) {
      if (this.disableBtn) return;
      try {
        const res = await this.$apollo.mutate({
          mutation: addUserToActivatie,
          variables: {
            input: {
              id,
            },
          },
        });
        if (res?.data?.addUserToActivatie.status !== 200)
          alert('er is iets mis gegaan');
        if (res?.data?.addUserToActivatie.status !== 200) return;
        this.$emit('update-amounts', id);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.score {
  position: relative;
}

.score::before,
.score::after {
  display: block;
  width: 100%;
  text-align: center;
}

.score::before {
  content: attr(data-total);
}

.score::after {
  content: attr(data-added);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
}

.score.animate::before {
  animation-name: fade-in;
  animation-duration: 2s;
}

@keyframes fade-in-scale {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.score.animate::after {
  animation-name: fade-in-up;
  animation-duration: 1s;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
