<template>
  <section
    class="bg-primary py-4 flex items-center justify-center text-aditionalTxt"
  >
    <div class="w-10/12 flex flex-col">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default { name: 'Reservation-top-section' };
</script>
