<template>
  <div
    class="w-11/12 mx-auto p-4 border-2 mb-3"
    :class="dark ? 'bg-primary' : 'bg-card'"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      required: false,
    },
  },
};
</script>
