<template>
  <main>
    <Header />
    <ReservationTopSection class="mb-4" style="height: calc(235px - 58px)">
      <h1 class="text-2xl pb-1">
        {{ teamName ? teamName : reservationUnAuth?.team?.name }}
      </h1>
      <p class="text-lg">
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'people-group']"
        />
        {{ reservationUnAuth?.numberofguests }} personen
      </p>
      <p class="text-lg" v-if="!reservationUnAuth?.status">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'ban']" />
        Booking geannuleerd
      </p>
      <p class="text-lg" v-else-if="reservationUnAuth?.status === 2">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'hourglass']" />
        Booking in behandeling
      </p>
      <p class="text-lg" v-else>
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'circle-check']"
        />
        Boeking bevestigd
      </p>
    </ReservationTopSection>
    <Card>
      <div class="mx-auto flex flex-col items-center py-4">
        <Title class="text-center" v-if="reservationUnAuth"
          >Je reservering is geslaagd!</Title
        >
        <Title class="text-center" v-else>Er is iets mis gegaan</Title>
        <div
          class="w-10/12 mx-auto rounded-full relative overflow-hidden"
          style="padding-bottom: 83.333333%; border: solid 2px #4eb788"
        >
          <img
            v-if="reservationUnAuth"
            class="h-full w-auto absolute"
            src="https://media0.giphy.com/media/pa37AAGzKXoek/giphy.gif?cid=cacc1dafe6uuczc43t0g8indj4prmtyz029z5bg16a6cg2n9&rid=giphy.gif&ct=g"
          />
          <img
            v-else
            class="h-full w-auto absolute"
            src="https://media3.giphy.com/media/8vUEXZA2me7vnuUvrs/giphy.gif?cid=cacc1daf44913552a5f5e33751528aaa10864ddca08c57b9&rid=giphy.gif&ct=g"
          />
        </div>
      </div>
    </Card>

    <div class="w-11/12 mx-auto mb-3">
      <div class="mx-auto flex flex-col">
        <PrimaryButton
          class="btn"
          v-if="
            this.$route.params.reservationnumber &&
            this.$route.params.reservationnumber != 'null'
          "
          @click="
            this.$router.push({
              path: `/reservation/${this.$route.params.reservationnumber}/payment`,
            })
          "
        >
          <div class="py-3 text-aditionalTxt text-xl">Naar het overzicht</div>
        </PrimaryButton>
      </div>
    </div>
  </main>
</template>
<script>
import { getUnAuthReservation } from '@/graphql/reservations/query.js';
import { getPaidAmount } from '@/getPaidAmount';

import Header from '@/components/partials/Header.vue';
import ReservationTopSection from '@/components/ReservationTopSection.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Card from '@/components/partials/Card.vue';
import Title from '@/components/partials/Title';

export default {
  components: {
    Header,
    ReservationTopSection,
    PrimaryButton,
    Card,
    Title,
  },

  data() {
    return {
      voucherPayment: false,
      voucherVal: null,
      voucherInitialVal: null,
    };
  },

  mounted() {
    this.$apollo.queries.reservationUnAuth.refetch();
  },

  apollo: {
    reservationUnAuth() {
      return {
        query: getUnAuthReservation,
        networkPolicy: 'network-first',
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        result({ data }) {
          if (data?.reservationUnAuth?.status === 400) {
            this.$router.push({ name: 'Reservations' });
          }
          this.teamName = data?.reservationUnAuth?.team?.name;
          if (data.reservationUnAuth.voucher) {
            this.voucherInitialVal =
              data.reservationUnAuth.voucher?.initialAmount;

            this.voucherPayment = true;
            this.voucherVal = data.reservationUnAuth.voucher?.amount;
          }
          this.$store.commit(
            'setBoekinsStatus',
            data?.reservationUnAuth?.status
          );
          this.$store.commit(
            'setChangedName',
            data?.reservationUnAuth?.team?.changedName
          );
          this.$store.commit(
            'setTeamName',
            data?.reservationUnAuth?.team?.name
          );
          this.$store.commit(
            'setPaymentStatus',
            data?.reservationUnAuth?.payment_status
          );
        },
      };
    },
  },

  computed: {
    payedObj() {
      return getPaidAmount(this.reservationUnAuth);
    },

    totalDepositAmount() {
      return (
        this.reservationUnAuth?.numberofguests *
        this.reservationUnAuth?.deposit_fee.amount
      );
    },

    totalRemnantAmount() {
      return (
        this.reservationUnAuth?.numberofguests *
          this.reservationUnAuth?.remnant_fee.amount -
        this.totalDepositAmount
      );
    },

    toPaytype() {
      if (
        this.payedObj.deposit.amount < this.totalDepositAmount ||
        this.reservationUnAuth?.status == 2
      ) {
        return 'deposit';
      } else {
        return 'normal';
      }
    },

    totalNOG() {
      return this.reservationUnAuth?.numberofguests + this.toAdd;
    },
  },
};
</script>
