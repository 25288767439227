import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

// auth
import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import SetNewPass from '@/views/auth/SetNewPass.vue';
// views
import Reservations from '@/views/Reservations.vue';
import Reservation from '@/views/Reservation.vue';
import Payment from '@/views/Payment.vue';
import Team from '@/views/Team.vue';
import Tenten from '@/views/Tent.vue';
import QR from '@/views/QR.vue';
import Media from '@/views/Media.vue';
import News from '@/views/News.vue';
import Contact from '@/views/Contact.vue';
import Thnx from '@/views/Thnx.vue';
import Table from '@/views/Table.vue';
import FeedbackForm from '@/views/FeedbackForm.vue';

import ReservationForm from '@/views/ReservationForm.vue';
import ResConfirm from '@/views/ResConfirm.vue';

// todo - components
// import Todo from '@/views/Todo.vue';
import TodoSplit from '@/views/TodoSplit.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/passwordReset',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/setNewpassword/:jwt',
    name: 'SetNewPass',
    component: SetNewPass,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/activate/:jwt',
    name: 'Activate',
    component: Register,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/activate/login/:userJwt',
    name: 'ActivateUser',
    component: Login,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/invite/:TeamGuid',
    name: 'Invite',
    component: Register,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/invite/login/:TeamGuid',
    name: 'InviteLogin',
    component: Login,
    meta: {
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/',
    name: 'Reservations',
    component: Reservations,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: true,
      showNav: true,
    },
  },
  {
    path: '/betaalverzoek/:type/:reservationnumber',
    name: 'TodoSplit',
    component: TodoSplit,
    meta: {
      transition: 'fade',
      requiresAuth: false,
      showNav: false,
    },
  },
  {
    path: '/reservation/:reservationnumber',
    name: 'Reservation',
    component: Reservation,
    props: (route) => ({
      ...route.params,
    }),
    meta: {
      requiresAuth: true,
      showNav: true,
    },
    children: [
      {
        path: 'payment',
        name: 'Payment',
        component: Payment,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          showNav: true,
        },
      },
      {
        path: 'tenten',
        name: 'Tenten',
        component: Tenten,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          showNav: true,
        },
      },
      {
        path: 'team',
        name: 'Team',
        component: Team,
        props: (route) => ({
          ...route.params,
        }),
        meta: {
          requiresAuth: true,
          showNav: true,
        },
      },
    ],
  },
  {
    path: '/reservation/:reservationnumber/bedankt',
    name: 'Thnx',
    component: Thnx,
    meta: {
      showNav: false,
    },
  },
  {
    path: '/qr/:reservationnumber',
    name: 'QR',
    component: QR,
    meta: {
      requiresAuth: true,
      transition: 'fadeQuick',
      mode: 'out-in',
      showNav: false,
    },
  },
  {
    path: '/media',
    name: 'Media',
    component: Media,
    meta: {
      showNav: true,
    },
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      showNav: true,
      transition: 'scale-slide',
    },
  },
  {
    path: '/table',
    name: 'Table',
    component: Table,
    meta: {
      showNav: true,
      transition: 'scale-slide',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      showNav: true,
    },
  },
  {
    path: '/reserveren',
    name: 'ReservationForm',
    component: ReservationForm,
  },
  {
    path: '/ResConfirm/:reservationnumber',
    name: 'ResConfirm',
    component: ResConfirm,
    meta: {
      transition: 'fade',
      mode: 'out-in',
      showNav: false,
    },
  },
  {
    path: '/FeedbackForm',
    name: 'FeedbackForm',
    component: FeedbackForm,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const fromName = from.name;

  if (fromName == 'Reservations' || fromName == 'Login') {
    to.meta.transition = 'scale-slide';
  }
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.name != 'Login'
  ) {
    if (!store.getters.isLoggedIn) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    if (store.getters.isLoggedIn && to.name == 'Login') {
      next({ name: 'Reservations' });
    } else {
      next();
    }
  }
});

export default router;
