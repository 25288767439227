<template>
  <ReservationTopSection class="py-11" style="height: 15vh">
    <div class="text-center">
      <h2 class="text-secondaryHeading text-4xl">Feedback</h2>
    </div>
  </ReservationTopSection>
  <iframe
    src="https://docs.google.com/forms/d/e/1FAIpQLSd9VouJkwHnLaqrE4ChoGerWBDVSVLHnxFgwckgRrb8z70TGw/viewform?embedded=true"
    class="w-full h-screen pb-20"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    >Laden…</iframe
  >
</template>

<script>
import ReservationTopSection from '@/components/ReservationTopSection.vue';
export default {
  name: 'feedbackForm',
  components: {
    ReservationTopSection,
  },
};
</script>
