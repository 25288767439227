import gql from 'graphql-tag';

export const createPayment = gql`
  mutation createPayment($input: CreatePaymentsInput!) {
    createPayment(input: $input) {
      reservationPayment {
        reservationID
        memberID
        amount
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        status
      }
      paymentUrl
    }
  }
`;
