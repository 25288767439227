import gql from 'graphql-tag';

export const changeTeamName = gql`
  mutation changeTeamName($input: ChangeTeamNameInput!) {
    changeTeamName(input: $input) {
      status
      statusMsg
    }
  }
`;

export const removeMember = gql`
  mutation removeMember($guid: String!, $reservationnumber: String!) {
    removeMember(guid: $guid, reservationnumber: $reservationnumber) {
      status
      statusMsg
    }
  }
`;
