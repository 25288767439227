<template>
  <div
    style="background-color: #fff"
    class="bg-white w-screen h-screen md:w-full md:h-full flex items-center justify-center select-none flex-col px-3"
  >
    <div
      @click="$router.go(-1)"
      class="absolute top-5 right-5 text-link text-4xl"
    >
      <font-awesome-icon :icon="['fa', 'xmark']" />
    </div>
    <h2 v-if="owner.name" class="text-link text-center text-4xl mb-4">
      {{ owner.name }}
    </h2>
    <h4 class="text-link text-center text-2xl mb-6">
      Jouw ticket voor Teamweekender
    </h4>
    <div style="min-height: calc(100vw - 40px)">
      <transition name="fade">
        <img v-if="qrImg" :src="qrImg" />
      </transition>
    </div>
  </div>
</template>

<script>
import { myQr } from '@/graphql/user/query.js';
const QRCode = require('qrcode');

export default {
  data() {
    return {
      QrOpen: false,
      qrImg: false,
      opts: {
        errorCorrectionLevel: 'M',
        type: 'image/jpeg',
        margin: 1,
        color: {
          dark: '#256EFF',
          light: '#fff',
        },
        width: 0,
      },
      ticketGuid: '',
      owner: {},
    };
  },

  mounted() {
    this.owner = JSON.parse(this.$route.params.owner);
    if (window.innerHeight >= window.innerWidth) {
      this.opts.width = window.innerWidth - 40;
    } else {
      this.opts.width = window.innerHeight;
    }
  },

  apollo: {
    qrJwt() {
      const self = this;
      return {
        query: myQr,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
            ticketGuid: this.$route.params.ticketGuid,
          };
        },
        skip: () =>
          !this.$route.params.reservationnumber ||
          !this.$route.params.ticketGuid,
        result({ data }) {
          QRCode.toDataURL(data.myQr, this.opts, function (err, data) {
            if (err) throw err;
            self.qrImg = data;
          });
        },
      };
    },

    myQr() {
      const self = this;
      return {
        query: myQr,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
            userGuid: this.owner.guid,
          };
        },
        skip: () =>
          this.$route.params.ticketGuid ||
          !this.$route.params.reservationnumber ||
          !this.owner.guid,
        result({ data }) {
          QRCode.toDataURL(data.myQr, this.opts, function (err, data) {
            if (err) throw err;
            self.qrImg = data;
          });
        },
      };
    },
  },
};
</script>
