<template>
  <Header />
  <!-- <section class="pt-14 flex items-center justify-center text-aditionalTxt"> -->
  <div
    class="bg-primary flex items-center justify-center"
    style="height: calc(100vh - 58px)"
  >
    <Card class="w-10/12 max-w-screen-sm overflow-hidden flex justify-center">
      <div class="w-full sm:w-10/12 flex flex-col justify-center items-center">
        <div class="mb-6 text-center">
          <h2 class="text-primaryHeading text-3xl mb-3 font-bold">
            Wachtwoord vergeten?
          </h2>
          <p class="mb-2 text-lg">
            Reset je wachtwoord door hieronder je email in te vullen. Wij sturen
            je vervolgens een email met een reset link.
          </p>
        </div>
        <form class="w-full text-center" ref="form" @submit="submitReset">
          <basic-input
            :_uid="'email'"
            :required="true"
            :type="'email'"
            :placeholder="'Email'"
          />
          <button type="submit">
            <PrimaryButton class="inline-block px-9">
              <div class="py-2 text-aditionalTxt text-lg">Stuur email</div>
            </PrimaryButton>
          </button>
        </form>
      </div>
    </Card>
  </div>
  <Modal class="flex align-center" v-if="showModal">
    <div class="w-11/12 mx-auto flex justify-center flex-col">
      <h2 class="text-black text-2xl mb-3 font-bold">
        {{ status === 200 ? 'Gelukt' : 'Er ging wat mis' }}
      </h2>
      <p class="mb-6">
        {{ modalMsg }}
      </p>
      <div class="flex justify-center w-10/12 mx-auto">
        <PrimaryButton @click="$router.push({ name: 'Login' })">
          Sluiten
        </PrimaryButton>
      </div>
    </div>
  </Modal>
</template>

<script>
// Graphql
import { ResetPassword } from '@/graphql/user/query.js';

// components
import Header from '@/components/partials/Header';
import BasicInput from '@/components/inputs/BasicInput.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Card from '@/components/partials/Card.vue';
import Modal from '@/components/partials/Modal.vue';

export default {
  name: 'ResetPassword',

  components: {
    Header,
    BasicInput,
    PrimaryButton,
    Card,
    Modal,
  },

  data() {
    return {
      showModal: false,
      modalMsg: '',
      status: 200,
    };
  },

  mounted() {
    document.body.classList.add('bg-secondary');
  },

  beforeUnmount() {
    document.body.classList.remove('bg-secondary');
    document.body.classList.add('bg-gray-100');
  },

  methods: {
    async submitReset(e) {
      e.preventDefault();
      const email = e.target.elements['input-email'].value;
      try {
        const res = await this.$apollo.query({
          query: ResetPassword,
          variables: {
            email,
          },
        });
        const response = res?.data?.ResetPassword;
        this.status = response?.status;
        if (response?.status !== 200) {
          this.modalMsg = response?.statusMsg;
          this.showModal = true;
          return;
        } else {
          this.modalMsg =
            'Je ontvangt binnen 5 minuten een mail met reset link.';
          this.showModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
