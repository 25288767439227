<template>
  <h1>This is the contact page</h1>
</template>

<script>
export default {
  name: 'Contact',

  components: {},
};
</script>
