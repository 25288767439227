<template>
  <nav
    class="fixed bottom-0 w-full shadow-md bg-white m-w-screen md:max-w-full w-screen"
    style="height: 65px"
  >
    <ul class="flex h-full items-center">
      <li class="flex-1">
        <a
          class="block text-center py-4 text-dark"
          href="/"
          :class="
            /reservation/.test($route.path) || $route.path == '/'
              ? 'text-icon'
              : ''
          "
        >
          <font-awesome-icon
            class="text-2xl"
            :icon="['fas', 'house-chimney']"
          />
        </a>
      </li>
      <li class="flex-1">
        <a
          class="block text-center py-4"
          href="https://i.splitt.nl"
          target="_blank"
        >
          <font-awesome-icon class="text-2xl" :icon="['fas', 'euro-sign']" />
        </a>
      </li>
      <li class="flex-1">
        <router-link
          class="block text-center py-4 relative"
          :to="'/news'"
          :active-class="'text-icon'"
        >
          <font-awesome-icon
            v-if="$store.getters.newNoti"
            class="text-2xl text-icon"
            :icon="['fal', 'comment-exclamation']"
          />
          <font-awesome-icon
            v-else
            class="text-2xl"
            :icon="['fas', 'comment']"
          />
          <Indicator
            v-if="$store.getters.newNoti"
            :classNames="'absolute top-1/3 right-1/4 transform -translate-x-1/2 -translate-y-1/2 bg-btnBg'"
          />
        </router-link>
      </li>
      <li class="flex-1">
        <router-link
          class="block text-center py-4"
          :to="'/table'"
          :active-class="'text-icon'"
        >
          <font-awesome-icon class="text-2xl" :icon="['fas', 'calendar']" />
        </router-link>
      </li>
      <li class="flex-1">
        <router-link
          class="block text-center py-4"
          :to="'/media'"
          :active-class="'text-icon'"
        >
          <font-awesome-icon class="text-2xl" :icon="['fas', 'photo-film']" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { newNotifications } from '@/graphql/events/query.js';
import Indicator from '@/components/elements/Indicator.vue';

export default {
  name: 'Header',
  components: {
    Indicator,
  },
  mounted() {
    console.log('mount');
    this.$apollo.queries.NotificationItems.refetch();
  },
  apollo: {
    NotificationItems() {
      return {
        query: newNotifications,
        client: 'storyBlok',
        networkPolicy: 'network-only',
        variables() {
          return {
            per_page: 1,
            page: 1,
          };
        },
        pollInterval: 20000,
        result({ data }) {
          if (data?.data?.NotificationItems?.items.length < 1) return;
          const newNoti =
            data?.NotificationItems?.items[0].id !==
            this.$store.getters.lastFetchedNotification;

          this.$store.commit('setNewNoti', newNoti);
        },
      };
    },
  },
  watch: {
    NotificationItems(data) {
      if (data?.items.length < 1) return;
      const newNoti =
        data?.items[0].id !== this.$store.getters.lastFetchedNotification;
      this.$store.commit('setNewNoti', newNoti);
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 768px) {
  nav {
    max-width: 460px;
  }
}
</style>
