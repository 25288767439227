<template>
  <main
    class="pt-3 flex-grow md:w-full md:max-w-full w-screen max-w-screen bg-body"
  >
    <Card v-if="!changedName && isMainBooker">
      <div class="mx-auto flex flex-col py-4">
        <Title>
          {{ team?.name }}
        </Title>
        <p class="mb-3">
          Pas hier je teamnaam aan. Let op: Je mag de teamnaam maximaal 1 keer
          aanpassen.
        </p>
        <basic-input
          class="w-full"
          v-model="newName"
          :_uid="'1'"
          :placeholder="team?.name"
        />
        <PrimaryButton
          @click="openTeamNameModal()"
          class="h-full"
          :class="newName == '' ? 'bg-btnDisabledBg' : ''"
        >
          Bevestigen
        </PrimaryButton>
      </div>
    </Card>

    <Card class="relative">
      <span
        ref="arrows"
        @click="refreshTeam"
        class="absolute right-4 cursor-pointer"
      >
        <font-awesome-icon :icon="['fas', 'arrows-rotate']" />
      </span>
      <div class="mx-auto flex flex-col py-4">
        <Title> teamgenoten toevoegen </Title>
        <p class="mb-3">
          Voeg je teamgenoten toe door onderstaande link via WhatsApp te delen.
          Tip: verzend de link naar jullie groepsapp.
        </p>
        <div class="flex w-full">
          <basic-input
            @click="copyVal"
            class="w-9/12 h-full"
            v-model="TeamInviteUrl"
            :_uid="'2'"
            :readonly="true"
          />
          <div class="w-3/12">
            <PrimaryButton @click="ShareTeamLink" class="h-full grow">
              <font-awesome-icon size="lg" :icon="['fab', 'whatsapp']" />
            </PrimaryButton>
          </div>
        </div>
        <div class="text-center" style="min-height: 26px">
          <transition>
            <span v-if="copyed">gekopiërd</span>
          </transition>
        </div>

        <div class="w-full">
          <div v-if="reservationMembers?.length === 0" class="text-center">
            Er ging iets mis.
          </div>
          <div
            v-else
            v-for="(member, index) in reservationMembers"
            :key="`member-${index}`"
            class="w-full flex justify-between items-center p-3 mb-1"
            :class="member?.ticketcode_used ? 'bg-green-100' : 'bg-body'"
            style="min-height: 50px"
          >
            <div class="text-xl flex items-center">
              {{ member?.user?.name }}
            </div>
            <div class="flex items-center relative text-black">
              <div
                v-if="
                  myUser?.main_booker &&
                  !member.user?.main_booker &&
                  member.user?.name &&
                  !member?.ticketcode_used
                "
                @click="openRemoveModal(member.user?.guid)"
              >
                <font-awesome-icon class="mr-2" :icon="['fas', 'user-slash']" />
              </div>
              <div
                v-if="member?.user?.guid == myUser?.guid || myUser?.main_booker"
                class="ml-3"
              >
                <div
                  v-if="$route.params.reservationnumber"
                  @click="toQr({ name: member.user?.name }, member.ticketcode)"
                >
                  <font-awesome-icon :icon="['fas', 'qrcode']" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
    <!-- v-if="payedObj.remnant.amount <= 0" -->
    <Card>
      <div class="mx-auto flex flex-col">
        <Title> Bijboeken </Title>
        <p class="mb-3" v-if="isMainBooker">
          Toch een teamgenoot extra? Of wil je een tent bijboeken?
        </p>
        <p class="mb-3" v-else>
          Toch een teamgenoot extra? Vraag de hoofdboeker of hij bij kan
          bijboeken
        </p>

        <PrimaryButton
          v-if="isMainBooker"
          @click="showAddNOG = !showAddNOG"
          class="mb-3"
        >
          Bijboeken
        </PrimaryButton>

        <div
          :class="showAddNOG ? 'max-h-96' : 'max-h-0'"
          class="transition-max-height duration-500 overflow-hidden grid grid-cols-4 gap-4"
        >
          <div class="flex items-center justify-center col-span-3">
            <div
              @click="subtractMember"
              class="btn bg-btnBg relative rounded-full p-4"
              :class="toAdd > 0 ? 'bg-btnBg' : 'bg-btnDisabledBg'"
            >
              <span
                class="text-aditionalTxt text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                -
              </span>
            </div>
            <BasicInput
              v-model="totalNOG"
              type="'number'"
              :readonly="true"
              class="mx-4 text-center"
            />
            <div
              @click="addMember"
              class="btn bg-btnBg relative rounded-full p-4"
            >
              <span
                class="text-aditionalTxt text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              >
                +
              </span>
            </div>
          </div>
          <PrimaryButton
            :class="toAdd > 0 ? 'bg-btnBg' : 'bg-btnDisabledBg'"
            @click="showModal = toAdd > 0 ? true : false"
          >
            <font-awesome-icon :icon="['fas', 'check']" />
          </PrimaryButton>
        </div>
      </div>
    </Card>

    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Weet je het zeker?</h2>
        <p class="mb-6">Bijboeken is defenitief.</p>
        <div class="grid grid-cols-2 gap-4 pt-3">
          <PrimaryButton @click="addNOG"> Bevestigen </PrimaryButton>
          <div
            class="flex items-center justify-center bg-red-300"
            @click="showModal = false"
          >
            Annuleren
          </div>
        </div>
      </div>
    </Modal>

    <Modal class="flex align-center" v-if="showModalTeamName">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Weet je het zeker?</h2>
        <p>
          Je teamnaam kan maar één keer worden gewijzigd. Denk hier dus goed
          over na.
        </p>
        <div class="grid grid-cols-2 gap-4 pt-3">
          <PrimaryButton @click="updateTeamName">Bevestigen</PrimaryButton>
          <div
            class="flex items-center justify-center bg-red-300"
            @click="showModalTeamName = !showModalTeamName"
          >
            Annuleren
          </div>
        </div>
      </div>
    </Modal>

    <Modal class="flex align-center" v-if="removeMemberModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Weet je het zeker?</h2>
        <p>
          Je staat op het punt een teamlid uit je team te verwijderen. Weet je
          het zeker?
        </p>
        <div class="grid grid-cols-2 gap-4 pt-3">
          <PrimaryButton @click="RemoveMember()"> Bevestigen </PrimaryButton>
          <div
            class="flex items-center justify-center bg-red-300"
            @click="removeMemberModal = false"
          >
            Annuleren
          </div>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { getPaidAmount } from '@/getPaidAmount';
import { getReservation } from '@/graphql/reservations/query.js';
import { addNumberOfGuests } from '@/graphql/reservations/mutations.js';

import { getTeam, getreservationMembers } from '@/graphql/team/query.js';
import { myUser } from '@/graphql/user/query.js';
import { changeTeamName, removeMember } from '@/graphql/team/mutations.js';
import Card from '@/components/partials/Card.vue';
import BasicInput from '@/components/inputs/BasicInput.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Modal from '@/components/partials/Modal';
import Title from '@/components/partials/Title';

export default {
  components: {
    Card,
    BasicInput,
    PrimaryButton,
    Modal,
    Title,
  },

  data() {
    return {
      newName: '',
      copyed: false,
      toAdd: 0,
      showAddNOG: false,
      showModal: false,
      showModalTeamName: false,
      errorModal: false,
      componentKey: 0,
      teamName: false,
      removeMemberModal: false,
    };
  },

  mounted() {
    if (typeof this.$route.params.reservationnumber == 'undefined')
      this.$router.go('-1');

    this.$apollo.queries.reservationMembers.refetch();
    this.$apollo.queries.reservation.refetch();
  },

  apollo: {
    team() {
      return {
        query: getTeam,
        networkPolicy: 'network-first',
        result({ data }) {
          if (data?.team?.status === 400) {
            this.$router.push({ name: 'Reservations' });
          }
          this.teamName = data?.team?.name;
          this.$store.commit('setChangedName', data?.team?.changedName);
          this.$store.commit('setTeamName', data?.team?.name);
        },
      };
    },
    reservationMembers() {
      return {
        query: getreservationMembers,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        networkPolicy: 'network-first',
      };
    },
    myUser() {
      return {
        query: myUser,
        networkPolicy: 'network-first',
      };
    },
    reservation() {
      return {
        query: getReservation,
        networkPolicy: 'network-first',
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        result({ data }) {
          if (data?.reservation?.status === 400) {
            this.$router.push({ name: 'Reservations' });
          }
          this.teamName = data?.reservation?.team?.name;
          this.$store.commit('setBoekinsStatus', data?.reservation?.status);
          this.$store.commit(
            'setNumberofguests',
            data?.reservation?.numberofguests
          );
          this.$store.commit(
            'setChangedName',
            data?.reservation?.team?.changedName
          );
          this.$store.commit(
            'setPaymentStatus',
            data?.reservation?.payment_status
          );
        },
      };
    },
  },

  computed: {
    changedName() {
      return this.$store.getters.changedName;
    },
    isMainBooker() {
      return (
        this.myUser?.guid == this.reservation?.team?.main_booker_user?.guid
      );
    },

    TeamInviteUrl() {
      const InviteUrl = `${process.env.VUE_APP_BASE_URL}/invite/${this.team?.guid}`;
      return InviteUrl;
    },

    payedObj() {
      return getPaidAmount(this.reservation);
    },

    totalNOG() {
      return this.reservationMembers?.length + this.toAdd;
    },
  },

  methods: {
    openTeamNameModal() {
      if (this.newName === '') return;
      this.showModalTeamName = true;
    },
    copyVal() {
      navigator.clipboard.writeText(this.TeamInviteUrl);
      this.copyed = true;
      setTimeout(() => (this.copyed = false), 1500);
    },

    ShareTeamLink() {
      const msg = `We hebben een eigen app voor Teamweekender! Je kunt O.a. je eigen ticket hier downloaden:  ${this.TeamInviteUrl}`;
      window.open(`https://api.whatsapp.com/send?text=${msg}`, '_blank');
    },

    openRemoveModal(guid) {
      this.guidToRemove = guid;
      this.removeMemberModal = true;
    },

    toQr(owner, ticketGuid) {
      this.$router.push({
        name: 'QR',
        params: {
          reservationnumber: this.$route.params.reservationnumber,
          owner: JSON.stringify(owner),
          ticketGuid,
        },
      });
    },

    async updateTeamName() {
      try {
        const res = await this.$apollo.mutate({
          mutation: changeTeamName,
          variables: {
            input: {
              newTeamName: this.newName,
            },
          },
        });

        this.componentKey += 1;
        this.showModalTeamName = false;
        this.$apollo.queries.team.refetch();
        if (res.data.changeTeamName.status) {
          this.$store.commit('setChangedName', true);
          this.$store.commit('setTeamName', this.newName);
        }
        this.newName = '';
        if (!res.data.changeTeamName.status) alert('Er is iets mis gegaan');
      } catch (e) {
        console.error(e);
      }
    },

    async RemoveMember() {
      try {
        const res = await this.$apollo.mutate({
          mutation: removeMember,
          variables: {
            guid: this.guidToRemove,
            reservationnumber: this.$route.params.reservationnumber,
          },
        });
        this.removeMemberModal = false;
        const response = res?.data?.removeMember;
        if (response.status !== 200) alert(response.statusMsg);
        this.$apollo.queries.reservationMembers.refetch();
      } catch (e) {
        console.error(e);
      }
    },

    async addNOG() {
      try {
        await this.$apollo.mutate({
          mutation: addNumberOfGuests,
          variables: {
            input: {
              reservationnumber: this.reservation?.reservationnumber,
              toAdd: this.toAdd,
            },
          },
        });

        this.toAdd = 0;
        this.$apollo.queries.reservation.refetch();
        this.$apollo.queries.reservationMembers.refetch();
        this.showModal = false;
      } catch (e) {
        console.error(e);
      }
    },

    refreshTeam() {
      this.$refs.arrows.classList.add('animate-spin');
      this.$apollo.queries.team.refetch();
      this.$apollo.queries.reservationMembers.refetch();
      setTimeout(() => {
        this.$refs.arrows.classList.remove('animate-spin');
      }, 1000);
    },

    addMember() {
      this.toAdd += 1;
    },

    subtractMember() {
      if (this.toAdd <= 0) return;
      this.toAdd -= 1;
    },
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
