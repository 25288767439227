export const getPaidAmount = (reservation) => {
  const payedAmount = {
    deposit: {
      nogPayed: 0,
      amount: 0,
    },
    remnant: {
      nogPayed: 0,
      amount: 0,
    },
  };
  if (reservation?.reservationPayments) {
    const SuccessfulPayments = reservation?.reservationPayments.filter(
      (payment) => payment.status === 1
    );

    SuccessfulPayments?.forEach(function (payment) {
      if (payment.type.includes('tent')) {
        console.log('tent');
      } else if (payment.status === 1 && payment.type.includes('deposit')) {
        payedAmount.deposit.nogPayed += payment.nog;
        payedAmount.deposit.amount += payment.amount;
      } else {
        payedAmount.remnant.nogPayed += payment.nog;
        payedAmount.remnant.amount += payment.amount;
      }
    });
  }

  return payedAmount;
};
