<template>
  <div class="text-center bg-gray-100 text-gray py-2">
    <h4 :class="colorClass ? colorClass : 'text-gray-400'">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    colorClass: {
      type: String,
    },
  },
};
</script>
