import gql from 'graphql-tag';

export const bookTents = gql`
  mutation bookTents($input: BookTents!) {
    BookTents(input: $input) {
      paymentUrl
      status
      statusMsg
    }
  }
`;
