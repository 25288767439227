import gql from 'graphql-tag';

export const RegisterUser = gql`
  mutation ($input: RegisterUserInput!) {
    RegisterUser(input: $input) {
      status
      statusMsg
    }
  }
`;

export const SubmitNewPass = gql`
  mutation SubmitNewPass($input: SetNewPassInput!) {
    SetNewPass(input: $input) {
      status
      statusMsg
    }
  }
`;

export const loginUser = gql`
  mutation ($input: LoginUserInput!) {
    loginUser(input: $input) {
      user {
        guid
        name
        surename
        email
        team_id
        main_booker
      }
      status
      statusMsg
    }
  }
`;

export const activateUser = gql`
  mutation ($jwt: String!) {
    activateUser(jwt: $jwt) {
      status
      statusMsg
    }
  }
`;
