<template>
  <main
    class="
      min-h-screen
      w-screen
      max-w-screen
      md:min-h-full md:w-full md:max-w-full
    "
  >
    <Header />
    <div
      class="bg-primary flex items-center justify-center"
      style="height: calc(100vh - 58px)"
    >
      <Card class="w-10/12 max-w-screen-sm overflow-hidden flex justify-center">
        <div
          class="
            w-full
            sm:w-10/12
            max-w-screen-sm
            flex flex-col
            justify-center
            items-center
          "
        >
          <div class="mb-6 text-center">
            <h2 class="text-primaryHeading text-3xl mb-3 font-bold">
              Inloggen
            </h2>
            <p class="mb-2 text-lg" v-if="TeamGuid">
              Door in te loggen neem je automatisch deel aan het team waar voor
              bent uitgenodigd.
            </p>
            <p class="mb-2 text-lg" v-else>
              Log hier in voor jouw eigen Teamweekender omgeving.
            </p>
            <p class="text-lg mb-3">
              Wachtwoord vergeten?
              <a :href="resetUrl" class="cursor-pointer text-link"
                >Klik hier.</a
              >
            </p>
          </div>
          <form
            class="w-full text-center"
            ref="form"
            @submit.prevent="submitLogin($event)"
          >
            <BasicInput
              :_uid="'email'"
              :required="true"
              :type="'email'"
              :placeholder="'Email'"
            />
            <BasicInput
              :_uid="'password'"
              :required="true"
              :placeholder="'Wachtwoord'"
              :type="'password'"
            />
            <button type="submit">
              <PrimaryButton class="inline-block px-9">
                <div class="py-2 text-aditionalTxt text-lg">Login</div>
              </PrimaryButton>
            </button>
          </form>
        </div>
      </Card>
    </div>
    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Er ging wat mis!</h2>
        <p class="mb-6">
          {{ errorMsg }}
        </p>
        <div class="flex justify-center w-10/12 mx-auto">
          <PrimaryButton @click="showModal = !showModal">
            Sluiten
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
// Graphql
import { loginUser, activateUser } from '@/graphql/user/mutations.js';
import { myUser } from '@/graphql/user/query.js';
// components
import Header from '@/components/partials/Header';
import BasicInput from '@/components/inputs/BasicInput.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Card from '@/components/partials/Card.vue';
import Modal from '@/components/partials/Modal';

export default {
  name: 'Login',

  components: {
    Header,
    BasicInput,
    PrimaryButton,
    Card,
    Modal,
  },

  data() {
    return {
      TeamGuid: null,
      resetUrl: `${process.env.VUE_APP_BASE_URL}/passwordReset`,
      showModal: false,
      errorMsg: '',
    };
  },

  apollo: {
    myUser() {
      return {
        query: myUser,
        networkPolicy: 'cache-first',
        skip: () => this.TeamGuid,
        result({ data }) {
          if (data?.myUser) {
            if (typeof this.$route.params.TeamGuid === 'undefined') {
              this.$store.commit('setLoggedIn', true);
              this.$router.push({ name: 'Reservations' });
            }
          }
        },
      };
    },
  },

  mounted() {
    document.body.classList.add('bg-secondary');

    if (typeof this.$route.params.userJwt != 'undefined') {
      this.activateUser();
    } else {
      if (typeof this.$route.params.TeamGuid != 'undefined') {
        this.TeamGuid = this.$route.params.TeamGuid;
      } else {
        this.$apollo.queries.myUser.refetch();
      }
    }
  },

  beforeUnmount() {
    document.body.classList.remove('bg-secondary');
    document.body.classList.add('bg-gray-100');
  },

  methods: {
    async submitLogin(e) {
      e.preventDefault();
      const email = e.target.elements['input-email'].value;
      const password = e.target.elements['input-password'].value;
      const TeamGuid = this.TeamGuid;

      try {
        const res = await this.$apollo.mutate({
          mutation: loginUser,
          variables: {
            input: {
              email,
              password,
              TeamGuid,
            },
          },
        });

        if (res?.data?.loginUser?.status !== 200) {
          this.errorMsg = res?.data?.loginUser?.statusMsg;
          this.showModal = true;
          return;
        }

        if (res?.data?.loginUser?.user) {
          this.$store.commit('setLoggedIn', true);
          this.$store.commit('setUserName', res?.data?.loginUser?.user);

          this.$router.push({
            name: 'Reservations',
            params: {
              myUser: JSON.stringify(res.data.loginUser.user),
            },
          });
        }
      } catch (e) {
        console.error(e);
        this.errorMsg = e;
        this.showModal = true;
      }
    },

    async activateUser() {
      try {
        const res = await this.$apollo.mutate({
          mutation: activateUser,
          variables: {
            jwt: this.$route.params.userJwt,
          },
        });

        if (res?.data?.activateUser?.status == 200) {
          this.$router.push({
            name: 'Reservations',
          });
        }
      } catch (e) {
        console.error(e);
        this.errorMsg = e;
        this.showModal = true;
      }
    },
  },
};
</script>
