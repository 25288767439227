<template>
  <slot />
  <div class="bg-white p-3 border-b-2" @click="LongTextOpen = !LongTextOpen">
    <div class="flex items-center">
      <div class="w-1/6" v-if="notification?.content?.icon?.filename">
        <img
          class="w-full"
          :src="notification?.content?.icon?.filename"
          alt=""
        />
      </div>
      <div class="w-5/6 pl-3">
        <div class="text-lg">
          {{ notification?.content?.title }}
        </div>
        <small>
          {{ notification?.content?.intro }}
        </small>
      </div>
    </div>
    <div
      class="transition-all duration-700 overflow-hidden longText"
      :class="LongTextOpen ? 'animationMaxHeight' : 'max-h-0'"
    >
      <div class="pt-2" v-html="richtext" />
    </div>
  </div>
</template>

<script>
import StoryblokClient from 'storyblok-js-client';

export default {
  props: {
    notification: {
      default: null,
    },
  },
  data() {
    return {
      LongTextOpen: false,
    };
  },
  computed: {
    richtext() {
      let Storyblok = new StoryblokClient({
        accessToken: process.env.VUE_APP_STORYBLOK,
      });
      return Storyblok.richTextResolver.render(
        this.notification?.content?.long_text
      );
    },
  },
};
</script>

<style scoped>
.animationMaxHeight {
  max-height: 2000px;
}
</style>
