<template>
  <router-link :to="`/reservation/${reservation?.reservationnumber}/payment`">
    <div class="bg-white p-3 border-b-2">
      <div class="text-lg">
        {{
          $date(reservation?.event?.startsAt).locale('nl').format('D MMMM YYYY')
        }}
      </div>
      <small>
        met {{ reservation?.team?.name }} - {{ reservation?.numberofguests }}
        personen
      </small>
      <br />
      <span class="bg-gray-100 py-1 px-2">
        <small>
          Geboekt door {{ reservation?.team?.main_booker_user?.name }}
        </small>
      </span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
};
</script>
