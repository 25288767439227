<template>
  <div
    class="
      bg-btnBg
      w-full
      text-center
      cursor-pointer
      flex
      items-center
      justify-center
    "
  >
    <div class="py-2 text-aditionalTxt text-lg font-bold">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
