<template>
  <div
    class="md:min-h-full w-screen md:max-w-full min-h-screen max-w-screen bg-body flex flex-col"
  >
    <ReservationTopSection class="py-11" style="height: 15vh">
      <div class="text-center">
        <h2 class="text-secondaryHeading text-4xl">Notificaties</h2>
      </div>
    </ReservationTopSection>
    <div
      class="messages overflow-scroll"
      @scroll="handleScroll()"
      style="height: calc(85vh - 70px)"
      v-if="NotificationItemsArr.length > 0"
    >
      <TitleListDivider
        class="dateDeviders dateDeviders__first fixed w-full max-w-full"
        ref="dateDeviders__first"
        style="top: 15vh; opacity: 0; max-width: 460px"
        :colorClass="'text-transparent'"
      />
      <NotificationItem
        v-for="(notification, index) in NotificationItemsArr"
        :key="notification.id"
        :notification="notification"
      >
        <TitleListDivider
          class="dateDeviders"
          v-if="
            $date(NotificationItemsArr[index - 1]?.first_published_at).format(
              'DD-MM-YYYY'
            ) !== $date(notification.first_published_at).format('DD-MM-YYYY')
          "
          :title="
            $date(notification.first_published_at).locale('nl').format('D MMMM')
          "
        />
        <TitleListDivider
          class="dateDeviders"
          v-else-if="index == 0"
          :title="
            $date(notification.first_published_at).locale('nl').format('D MMMM')
          "
        />
      </NotificationItem>
      <div ref="sensor"></div>
    </div>
    <div v-else>
      <p class="text-center pt-4">Er zijn nog geen notificaties.</p>
    </div>
  </div>
</template>

<script>
import ReservationTopSection from '@/components/ReservationTopSection.vue';
import TitleListDivider from '@/components/elements/TitleListDivider.vue';
import NotificationItem from '@/components/NotificationItem.vue';
import { notifications } from '@/graphql/events/query.js';

export default {
  name: 'Nieuwtjes',
  components: {
    ReservationTopSection,
    TitleListDivider,
    NotificationItem,
  },
  data() {
    return {
      page: 1,
      LongTextOpen: false,
      fetching: false,
      NotificationItemsArr: [],
    };
  },
  mounted() {
    this.$store.commit('setNewNoti', false);
  },
  apollo: {
    NotificationItems() {
      return {
        query: notifications,
        client: 'storyBlok',
        variables() {
          return {
            per_page: 10,
            page: this.page,
          };
        },
        result({ data }) {
          this.NotificationItemsArr = this.NotificationItemsArr.concat(
            data?.NotificationItems?.items
          );
          this.$store.commit(
            'setLastFetchedNotification',
            this.NotificationItemsArr[0].id
          );
          this.fetching = false;
        },
      };
    },
  },
  methods: {
    handleScroll() {
      if (
        this.$refs.sensor.getBoundingClientRect().bottom <=
          window.innerHeight + window.innerHeight / 5 &&
        !this.fetching
      ) {
        this.fetching = true;
        this.page = this.page + 1;
        this.$apollo.queries.NotificationItems.refetch();
      }

      const topLabel = document.querySelector('.dateDeviders__first');
      const messageBox = document.querySelector('.messages');

      const dateLabels = document.querySelectorAll('.dateDeviders');
      let currentLabel = null;
      dateLabels.forEach((dateLabel) => {
        if (
          messageBox.scrollTop + (window.innerHeight / 100) * 15 >=
          dateLabel.offsetTop
        ) {
          currentLabel = dateLabel;
        }
      });
      if (currentLabel) {
        topLabel.style.opacity = '1';
        topLabel.innerHTML = `<h4 class="text-gray-400">${currentLabel.innerText}</h4>`;
      } else {
        topLabel.style.opacity = '0';
      }
    },
  },
};
</script>
