<template>
  <div class="basic-input-container">
    <label :for="id" v-if="label" class="mb-2">
      {{ label }}
    </label>
    <input
      :style="inputStyle"
      class="w-full basic-input border-2 border-btnBorder px-4 py-3"
      :class="inputClass"
      :id="id"
      :key="id"
      :type="type"
      :name="name"
      :value="modelValue"
      :inputmode="inputmode"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :autocomplete="autocomplete"
      :autocorrect="autocorrect"
      :spellcheck="spellcheck"
      :placeholder="placeholder"
      :errMsg="errMsg"
      :showError="showError"
      :hasError="hasError"
      :max="max"
      @input="$emit('update:modelValue', $event.target.value)"
      @change="$emit('update:modelValue', $event.target.value)"
      @blur="onBlur"
      ref="input"
    />
    <transition v-if="!readonly && showError" name="slide-toggle">
      <div class="btn-card mt-1 bg-red color-light relative">
        <span
          class="text-red-400"
          :class="hasError ? 'opacity-1' : 'opacity-0'"
        >
          {{ errMsg ? errMsg : 'hidden' }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BasicInput',

  props: {
    modelValue: {
      type: [String, Number],
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      // default() {
      //   return this.id;
      // },
    },
    showError: {
      type: Boolean,
      default: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
    },
    inputmode: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    errMsg: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'new-password',
    },
    autocorrect: {
      type: String,
      default: 'false',
    },
    spellcheck: {
      type: String,
      default: 'false',
    },
    max: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'edit',
    },
    label: {
      type: String,
      default: null,
    },
    inputClass: {
      type: String,
    },
    inputStyle: {
      type: String,
    },
    _uid: {
      type: String,
    },
  },

  data() {
    return {
      didBlur: false,
    };
  },

  computed: {
    id() {
      return 'input-' + this._uid;
    },
    // hasError() {
    //   if (this.required && !this.value && this.didBlur) {
    //     return true;
    //   }
    //   return false;
    // },
  },

  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value);
    },

    onBlur() {
      this.didBlur = true;
    },
  },
};
</script>
<!-- 
<style lang="scss" scoped>
.basic-input-container {
  width: 100%;

  label {
    font-weight: 600;
    display: inline-block;
  }

  .basic-input {
    position: relative;
    border-radius: 0.3em;
    overflow: hidden;
    border: 1px solid #dadce0;
    box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 16%);
    background-color: var(--color-light);
    transition: all 0.2s;
  }

  .basic-input:focus {
    border: 1px solid var(--base-color);
    box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 16%),
      inset 0px -2px 0px 0px var(--base-color), 0px 1px 1px 0px rgb(0 0 0 / 16%);
    background-color: #fff;
  }

  .btn-card {
    font-size: 0.7em;
    font-weight: 600;
    letter-spacing: 0.025em;
    padding: 0.25em 0.5em;
    margin: -0.25em 0;
    border-radius: 0 0 0.75em 0.75em;
  }
}
</style>
 -->
