<template>
  <main class="flex-grow w-screen max-w-screen md:w-full md:max-w-full bg-body">
    <div class="pt-2">
      <Card v-if="voucherPayment">
        <div class="mx-auto flex flex-col py-4">
          <div class="grid grid-cols-3 gap-0">
            <PaymentItem
              :amount="totalDepositAmount"
              :payedAmount="payedObj.deposit.amount"
              :label="'Aanbetaling'"
            />

            <PaymentItem
              class="mx-1"
              :amount="totalRemnantAmount"
              :payedAmount="payedObj.remnant.amount"
              :label="'Restant'"
            />

            <PaymentItem
              :amount="voucherInitialVal"
              :payedAmount="voucherVal"
              :label="'voucher'"
            />
          </div>
        </div>
      </Card>
      <Card v-if="voucherPayment && voucherVal > 0">
        <Title>
          <span v-if="toPaytype == 'deposit'">Aanbetaling</span>
          <span v-else>Restant betaling</span>
        </Title>
        <p class="mb-3">
          Klik op de button en betaal met jullie voucher. Is de voucher-waarde
          niet voldoende? Dan beland je op de betaalpagina waar je het restant
          kan betalen.
        </p>
        <PrimaryButton v-if="paymentsStatus" class="bg-btnDisabledBg">
          Restant betalen met voucher
        </PrimaryButton>

        <PrimaryButton
          v-if="
            payedObj.deposit.amount >= totalDepositAmount &&
            reservation?.status == 2
          "
          class="bg-btnDisabledBg"
        >
          Aanbetalen met voucher
        </PrimaryButton>

        <PrimaryButton
          class="mb-3"
          :class="isMainBooker ? 'bg-btn' : 'bg-btnDisabledBg'"
          @click="payment"
          v-else-if="voucherVal > 0 && !paymentsStatus"
        >
          <div v-if="reservation?.status != 0">
            <span v-if="toPaytype == 'deposit'">Aanbetalen met voucher</span>
            <span v-else>Restant betalen met voucher</span>
          </div>
          <div v-else>Alles is betaald</div>
        </PrimaryButton>
        <div
          v-else-if="!voucherVal"
          class=""
          :class="
            reservation?.internal_remarks === 'splitt Vip'
              ? 'grid grid-cols-1'
              : 'grid grid-cols-2 gap-4'
          "
        >
          <PrimaryButton
            v-if="!reservation?.internal_remarks === 'splitt Vip'"
            :class="!paymentsStatus ? 'bg-btn' : 'bg-btnDisabledBg'"
            @click="SharePaymentRequest"
          >
            <span class="flex items-center justify-between">
              per teamlid
              <font-awesome-icon
                class="ml-2 pb-1"
                size="lg"
                :icon="['fab', 'whatsapp']"
              />
            </span>
          </PrimaryButton>

          <PrimaryButton class="bg-btnDisabledBg" v-if="paymentsStatus">
            <span>volledig betaald</span>
          </PrimaryButton>

          <PrimaryButton
            class="bg-btnDisabledBg"
            v-else-if="
              payedObj.deposit.amount >= totalDepositAmount &&
              reservation?.status == 2
            "
          >
            <span>Volledig betaald</span>
          </PrimaryButton>

          <PrimaryButton v-else @click="payment">
            <div v-if="reservation?.status != 0">
              <span v-if="toPaytype == 'deposit'">in één keer</span>
              <span v-else>in één keer</span>
            </div>
            <div v-else>Alles is betaald</div>
          </PrimaryButton>
        </div>
      </Card>
      <div v-else>
        <Card v-if="!voucherPayment">
          <div class="mx-auto flex flex-col pt-4">
            <div class="flex justify-center">
              <PaymentItem
                class="mx-2"
                :amount="totalDepositAmount"
                :payedAmount="payedObj.deposit.amount"
                :label="'Aanbetaling'"
              />

              <PaymentItem
                class="mx-2"
                :amount="totalRemnantAmount"
                :payedAmount="payedObj.remnant.amount"
                :label="'Restant betaling'"
              />
            </div>
          </div>
        </Card>
        <Card>
          <div class="mx-auto flex flex-col">
            <Title>
              <span v-if="toPaytype == 'deposit'">Aanbetaling</span>
              <span v-else>Restant betaling</span>
            </Title>
            <p class="mb-3" v-if="toPaytype == 'deposit'">
              Je kan de aanbetaling in één keer voldoen, of delen met je team.
            </p>
            <p class="mb-3" v-else>
              Je kan het restant in één keer voldoen, of delen met je team.
            </p>
            <div
              :class="
                reservation?.internal_remarks === 'splitt Vip'
                  ? ''
                  : 'grid grid-cols-2 gap-4'
              "
            >
              <PrimaryButton
                v-if="
                  payedObj.deposit.amount >= totalDepositAmount &&
                  reservation?.status == 2 &&
                  reservation?.internal_remarks !== 'splitt Vip'
                "
                class="bg-btnDisabledBg"
              >
                <span class="flex items-center justify-between">
                  per teamlid
                  <font-awesome-icon
                    class="ml-2 pb-1"
                    size="lg"
                    :icon="['fab', 'whatsapp']"
                  />
                </span>
              </PrimaryButton>

              <PrimaryButton
                v-else-if="reservation?.internal_remarks !== 'splitt Vip'"
                :class="!paymentsStatus ? 'bg-btn' : 'bg-btnDisabledBg'"
                @click="SharePaymentRequest"
              >
                <span class="flex items-center justify-between">
                  per teamlid
                  <font-awesome-icon
                    class="ml-2 pb-1"
                    size="lg"
                    :icon="['fab', 'whatsapp']"
                  />
                </span>
              </PrimaryButton>

              <PrimaryButton class="bg-btnDisabledBg" v-if="paymentsStatus">
                <span>volledig betaald</span>
              </PrimaryButton>

              <PrimaryButton
                class="bg-btnDisabledBg"
                v-else-if="
                  payedObj.deposit.amount >= totalDepositAmount &&
                  reservation?.status == 2
                "
              >
                <span>Volledig betaald</span>
              </PrimaryButton>

              <PrimaryButton v-else @click="payment">
                <div v-if="reservation?.status != 0">
                  <span v-if="toPaytype == 'deposit'">in één keer</span>
                  <!-- <span v-else>in één keer</span> -->
                  <span v-else>{{
                    reservation?.internal_remarks === 'splitt Vip'
                      ? 'Betaal de borg'
                      : 'in één keer'
                  }}</span>
                </div>
                <div v-else>Alles is betaald</div>
              </PrimaryButton>
            </div>
          </div>
        </Card>
      </div>

      <!-- v-if="payedObj.remnant.amount <= 0" -->
      <Card>
        <div class="mx-auto flex flex-col">
          <Title> Bijboeken </Title>
          <p class="mb-3" v-if="isMainBooker">
            Toch een teamgenoot extra meenemen? Je kan hier bijboeken.
          </p>
          <p class="mb-3" v-else>
            Toch nog een teamgenoot die mee wil? Vraag aan de hoofdboeker of
            hij/zij kan bijboeken.
          </p>

          <PrimaryButton
            v-if="isMainBooker"
            @click="showAddNOG = !showAddNOG"
            class="mb-3"
          >
            Bijboeken
          </PrimaryButton>

          <div
            :class="showAddNOG ? 'max-h-96' : 'max-h-0'"
            class="transition-max-height duration-500 overflow-hidden grid grid-cols-4 gap-4"
          >
            <div class="flex items-center justify-center col-span-3">
              <div
                @click="subtractMember"
                class="btn bg-btnBg relative rounded-full p-4"
                :class="toAdd > 0 ? 'bg-btnBg' : 'bg-btnDisabledBg'"
              >
                <span
                  class="text-aditionalTxt text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  -
                </span>
              </div>
              <BasicInput
                v-model="totalNOG"
                type="'number'"
                :readonly="true"
                class="mx-4 text-center"
              />
              <div
                @click="addMember"
                class="btn bg-btnBg relative rounded-full p-4"
              >
                <span
                  class="text-aditionalTxt text-2xl font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  +
                </span>
              </div>
            </div>
            <PrimaryButton
              :class="toAdd > 0 ? 'bg-btnBg' : 'bg-btnDisabledBg'"
              @click="showModal = toAdd > 0 ? true : false"
            >
              <font-awesome-icon :icon="['fas', 'check']" />
            </PrimaryButton>
          </div>
        </div>
      </Card>
    </div>
    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Weet je het zeker?</h2>
        <p class="mb-6">Bijboeken is defenitief.</p>
        <div class="grid grid-cols-2 gap-4 pt-3">
          <PrimaryButton @click="addNOG"> Bevestigen </PrimaryButton>
          <div
            class="flex items-center justify-center bg-red-300"
            @click="showModal = false"
          >
            Annuleren
          </div>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { myUser } from '@/graphql/user/query.js';
import { getReservation } from '@/graphql/reservations/query.js';
import { addNumberOfGuests } from '@/graphql/reservations/mutations.js';
import { createPayment } from '@/graphql/payments/mutations';

import Card from '@/components/partials/Card.vue';
import PaymentItem from '@/components/PaymentItem.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import BasicInput from '@/components/inputs/BasicInput.vue';
import Modal from '@/components/partials/Modal';
import Title from '@/components/partials/Title';

import { getPaidAmount } from '@/getPaidAmount';

export default {
  name: 'Todo',

  components: {
    Card,
    PaymentItem,
    PrimaryButton,
    BasicInput,
    Modal,
    Title,
  },

  data() {
    return {
      voucherPayment: false,
      voucherVal: null,
      voucherInitialVal: null,
      toAdd: 0,
      showAddNOG: false,
      showModal: false,
      teamName: '',
    };
  },

  mounted() {
    this.$apollo.queries.myUser.refetch();
    this.$apollo.queries.reservation.refetch();
  },

  apollo: {
    reservation() {
      return {
        query: getReservation,
        networkPolicy: 'cache-and-network',
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        result({ data }) {
          if (data?.reservation?.status === 400) {
            this.$router.push({ name: 'Reservations' });
          }
          this.teamName = data?.reservation?.team?.name;
          if (data.reservation.voucher) {
            this.voucherInitialVal = data.reservation.voucher?.initialAmount;

            this.voucherPayment = true;
            this.voucherVal = data.reservation.voucher?.amount;
          }

          this.$store.commit('setBoekinsStatus', data?.reservation?.status);

          this.$store.commit(
            'setNumberofguests',
            data?.reservation?.numberofguests
          );

          this.$store.commit(
            'setChangedName',
            data?.reservation?.team?.changedName
          );

          this.$store.commit(
            'setPaymentStatus',
            data?.reservation?.payment_status
          );

          this.$store.commit('setTeamName', data?.reservation?.team?.name);
        },
      };
    },

    myUser() {
      return {
        query: myUser,
        networkPolicy: 'network-first',
      };
    },
  },

  computed: {
    paymentsStatus() {
      return this.$store.getters.paymentsStatus;
    },
    isMainBooker() {
      return (
        this.myUser?.guid == this.reservation?.team?.main_booker_user?.guid
      );
    },

    payedObj() {
      return getPaidAmount(this.reservation);
    },

    totalDepositAmount() {
      return (
        this.reservation?.numberofguests * this.reservation?.deposit_fee.amount
      );
    },

    totalRemnantAmount() {
      return (
        this.reservation?.numberofguests *
          this.reservation?.remnant_fee.amount -
        this.totalDepositAmount
      );
    },

    toPaytype() {
      if (
        this.payedObj.deposit.amount < this.totalDepositAmount ||
        this.reservation?.status == 2
      ) {
        return 'deposit';
      } else {
        return 'normal';
      }
    },

    totalNOG() {
      return this.reservation?.numberofguests + this.toAdd;
    },
  },

  methods: {
    async payment() {
      if (!this.isMainBooker && this.voucherPayment && this.voucherVal) return;
      try {
        const res = await this.$apollo.mutate({
          mutation: createPayment,
          variables: {
            input: {
              reservationID: this.reservation.id,
              splitpayment: 0,
            },
          },
        });
        window.location.href = res.data.createPayment.paymentUrl;
      } catch (e) {
        console.error(e);
      }
    },

    async addNOG() {
      try {
        await this.$apollo.mutate({
          mutation: addNumberOfGuests,
          variables: {
            input: {
              reservationnumber: this.reservation?.reservationnumber,
              toAdd: this.toAdd,
            },
          },
        });
        // const response = res?.data?.AddNumberOfGuestsReservation;
        this.toAdd = 0;
        this.$apollo.queries.reservation.refetch();
        this.showModal = false;
      } catch (e) {
        console.error(e);
      }
    },

    SharePaymentRequest() {
      if (this.reservation?.payment_status) return;
      const payUrl = `${process.env.VUE_APP_BASE_URL}/betaalverzoek/${this.toPaytype}/${this.$route.params.reservationnumber}`;

      this.$router.push({ redirect: (window.location.href = payUrl) });
    },

    addMember() {
      this.toAdd += 1;
    },

    subtractMember() {
      if (this.toAdd <= 0) return;
      this.toAdd -= 1;
    },
  },
};
</script>
