<template>
  <div
    class="m-w-screen md:max-w-full w-screen min-h-screen md:min-h-full bg-body"
  >
    <ReservationTopSection style="height: calc(235px - 58px)">
      <div class="text-center">
        <h2 class="text-secondaryHeading text-4xl">
          <!-- Hi {{ MyUserObj?.name || myUser?.name }} -->
          dashboard
        </h2>
      </div>
    </ReservationTopSection>
    <TitleListDivider :title="'Reserveringen'" />
    <!-- loop over all reservations -->
    <div v-if="reservations?.length === 0" class="w-10/12 mx-auto">
      <p class="text-center py-3">Geen reserveringen gevonden</p>
      <a href="https://app.teamweekender.nl/reserveren">
        <PrimaryButton> click hier om te reserveren </PrimaryButton>
      </a>
    </div>
    <ReservationListItem
      v-else
      v-for="(reservation, index) in reservations"
      :key="`ListItem-${index}`"
      :reservation="reservation"
    />
  </div>
</template>

<script>
import { getReservations } from '@/graphql/reservations/query.js';
import { myUser } from '@/graphql/user/query.js';
import ReservationTopSection from '@/components/ReservationTopSection.vue';
import TitleListDivider from '@/components/elements/TitleListDivider.vue';
import ReservationListItem from '@/components/ReservationListItem.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';

export default {
  name: 'Reservations',

  components: {
    ReservationTopSection,
    TitleListDivider,
    ReservationListItem,
    PrimaryButton,
  },

  data() {
    return {
      MyUserObj: null,
    };
  },

  mounted() {
    if (this.$route.params.myUser) {
      this.MyUserObj = JSON.parse(this.$route.params.myUser);
    } else {
      this.MyUserObj = false;
    }

    this.$apollo.queries.reservations.refetch();
  },

  apollo: {
    myUser() {
      return {
        query: myUser,
        networkPolicy: 'cache-and-network',
        skip: () => !this.$store.getters.isLoggedIn || this.MyUserObj,
      };
    },
    reservations() {
      return {
        query: getReservations,
        // skip: () => !this.MyUserObj && !this.myUser,
        networkPolicy: 'network-first',
      };
    },
  },
};
</script>
