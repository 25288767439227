import gql from 'graphql-tag';

export const addNumberOfGuests = gql`
  mutation addNumberOfGuests($input: AddNumberOfGuests!) {
    AddNumberOfGuestsReservation(input: $input) {
      status
      statusMsg
    }
  }
`;

export const createReservation = gql`
  mutation Reservationcreate($input: CreateReservationInput!) {
    createReservation(input: $input) {
      status
      statusMsg
      reservationnumber
    }
  }
`;
