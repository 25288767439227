<template>
  <main
    class="
      pt-2
      md:m-h-full
      flex-grow
      md:w-full md:max-w-full
      m-h-screen
      flex-grow
      w-screen
      max-w-screen
      bg-body
    "
  >
    <Card>
      <Title> Tent boeken? </Title>
      <div
        v-if="
          boekinsStatus == 1
        "
      >
        <p class="text-lg mb-0 pb-1"> 
          Voor het weekend heb je twee opties:
        </p>
        <p>
          <ol class="list-decimal pl-6 pb-4">
            <li>
              Neem zonder meerprijs je eigen tent(en) mee
            </li>
            <li>
              Of kies hieronder voor een huurtent tegen bijbetaling
            </li>
          </ol>
          Wij zetten de tent voor je op en breken die af na het event. Er zit ook al een grondzeil bij. 
          <!-- Je moet zelf nog wel een luchtbed, slaapzak en kussen meenemen. -->
        </p>
        <div class="pt-8" v-if="tents?.length > 0">
          <div
            v-for="(tentQuantity, index) in tents"
            :key="`tent-${index}`"
            class="flex mb-4 flex-col"
          >
            <div class="flex justify-between">
              <h4 class="text-md grid grid-cols-3 w-10/12">
                <span>
                  {{ tentQuantity.tent?.name }}
                </span>
                <span>
                  &#8364;{{ getFormatedPrice(tentQuantity?.tent?.price) }}
                </span>
                <span>
                  nog
                  <span class="px-1">{{ tentQuantity?.quantity }}</span>
                </span>
              </h4>
              <div class="flex items-center">
                <div
                  @click="subtractTent(tentQuantity.tent?.id)"
                  class="btn relative rounded-full p-3"
                  :class="
                    options[`${tentQuantity?.tent?.id}`].quantity <=
                    findInitialAmount(tentQuantity?.tent?.id)
                      ? 'bg-btnDisabledBg'
                      : 'bg-btnBg'
                  "
                >
                  <span
                    class="
                      text-aditionalTxt text-xl
                      absolute
                      top-1/2
                      left-1/2
                      transform
                      -translate-x-1/2 -translate-y-1/2
                    "
                  >
                    -
                  </span>
                </div>
                <div class="px-2 text-center" style="min-width: 40px">
                  {{ options[`${tentQuantity.tent?.id}`].quantity }}
                </div>
                <div
                  @click="addTent(`${tentQuantity.tent?.id}`, tentQuantity)"
                  class="btn bg-btnBg relative rounded-full p-3"
                  :class="
                    optionsToAdd[`${tentQuantity?.tent?.id}`].quantity >=
                    tentQuantity?.quantity
                      ? 'bg-btnDisabledBg'
                      : 'bg-btnBg'
                  "
                >
                  <span
                    class="
                      text-aditionalTxt text-xl
                      absolute
                      top-1/2
                      left-1/2
                      transform
                      -translate-x-1/2 -translate-y-1/2
                    "
                  >
                    +
                  </span>
                </div>
              </div>
            </div>
            <small class="text-gray-500">
              {{ tentQuantity?.tent?.capacity_text }}
            </small>
          </div>
          <PrimaryButton
            :class="totalToAdd > 0 ? 'bg-btnBg' : 'bg-btnDisabledBg'"
            class="mt-6"
            @click="openModal"
          >
            Bijboeken
          </PrimaryButton>
        </div>
        <div class="pt-4 text-center" v-else>
          <b>De tentopties maken we later bekend.</b>
        </div>
      </div>
      <!-- <div v-else-if="!isMainBooker">
        <p>
          Wil jij een tent voor jullie team boeken? Jullie hoofdboeker kan dit
          in zijn dashboard regelen.
        </p>
      </div> -->
      <div v-else-if="reservation?.status == 2">
        <p>
          Jullie staan nog op de wachtlijst. Als jullie reservering defenitief
          is kunnen jullie een tent boeken.
        </p>
      </div>
      <div v-else>
        <p>
          Voltooi eerst jullie aanbetaling. Hierna kan je hier de opties voor de tenten zien en voor jouw team tent(en) reserveren.
        </p>
      </div>
    </Card>

    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Weet je het zeker?</h2>
        <p class="mb-6">Bijboeken is definitief en de tent moet gelijk betaald worden.</p>
        <div class="grid grid-cols-2 gap-4 pt-3">
          <PrimaryButton @click="bookTents"> Bevestigen </PrimaryButton>
          <div
            class="flex items-center justify-center bg-red-300"
            @click="showModal = false"
          >
            Annuleren
          </div>
        </div>
      </div>
    </Modal>

    <Modal class="flex align-center" v-if="showErrorModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">Er ging iets mis?!</h2>
        <p class="mb-6">{{ errorMsg }}</p>
        <div class="flex justify-center w-10/12 mx-auto">
          <PrimaryButton @click="showErrorModal = false">
            sluiten
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import { myUser } from '@/graphql/user/query.js';
import { getTents } from '@/graphql/tents/query.js';
import { bookTents } from '@/graphql/tents/mutation.js';
import { getReservation } from '@/graphql/reservations/query.js';
import { getPaidAmount } from '@/getPaidAmount';

import Card from '@/components/partials/Card.vue';
import Title from '@/components/partials/Title.vue';
import Modal from '@/components/partials/Modal';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
export default {
  components: {
    Card,
    Title,
    Modal,
    PrimaryButton,
  },

  data() {
    return {
      totalToAdd: 0,
      options: {},
      optionsToAdd: {},
      showModal: false,
      showErrorModal: false,
      errorMsg: '',
    };
  },
  mounted() {
    this.$apollo.queries.tents.refetch();
    this.$apollo.queries.reservation.refetch();
  },
  apollo: {
    tents() {
      return {
        query: getTents,
        networkPolicy: 'network-only',
        result({ data }) {
          for (let index = 0; index < data.tents.length; index++) {
            this.options[`${data.tents[index].tent.id}`] = { quantity: 0 };
            this.optionsToAdd[`${data.tents[index].tent.id}`] = {
              quantityID: data.tents[index].id,
              quantity: 0,
            };
          }
        },
      };
    },
    reservation() {
      return {
        query: getReservation,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        networkPolicy: 'cache-first',
        skip: () => !this.tents,
        result({ data }) {
          if (data?.reservation?.status === 400) {
            this.$router.push({ name: 'Reservations' });
          }

          if (data.reservation.voucher) {
            this.voucherInitialVal = data.reservation.voucher?.initialAmount;
            if (data.reservation.voucher?.amount > 0) {
              this.voucherPayment = true;
              this.voucherVal = data.reservation.voucher?.amount;
            }
          }

          if (data?.reservation?.tent && data?.reservation?.tent?.length > 0) {
            for (
              let index = 0;
              index < data?.reservation?.tent.length;
              index++
            ) {
              if (
                this.options[
                  `${data?.reservation?.tent[index].tent_optionID}`
                ] &&
                data?.reservation?.tent[index].status === 1
              ) {
                this.options[
                  `${data?.reservation?.tent[index].tent_optionID}`
                ].quantity += parseInt(data?.reservation?.tent[index].quantity);
              }
            }
          }

          this.$store.commit('setBoekinsStatus', data?.reservation?.status);

          this.$store.commit(
            'setChangedName',
            data?.reservation?.team?.changedName
          );

          this.$store.commit('setTeamName', data?.reservation?.team?.name);

          this.$store.commit(
            'setPaymentStatus',
            data?.reservation?.payment_status
          );
        },
      };
    },
    myUser() {
      return {
        query: myUser,
        networkPolicy: 'network-first',
      };
    },
  },

  computed: {
    boekinsStatus() {
      return this.$store.getters.boekinsStatus;
    },
    payedObj() {
      return getPaidAmount(this.reservation);
    },

    totalDepositAmount() {
      return (
        this.reservation?.numberofguests * this.reservation?.deposit_fee.amount
      );
    },
    isMainBooker() {
      return (
        this.myUser?.guid == this.reservation?.team?.main_booker_user?.guid
      );
    },
  },

  methods: {
    openModal() {
      if (!this.totalToAdd) return;
      this.showModal = true;
    },

    getFormatedPrice(amount) {
      return new Intl.NumberFormat('en-EN', {
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(amount / 100);
    },

    findInitialAmount(id) {
      let InitialTentsAmound = 0;
      this.reservation.tent.filter((obj) => {
        if (obj.tent_optionID === id && obj.status === 1) {
          InitialTentsAmound += obj.quantity;
        }
      });

      return InitialTentsAmound;
    },

    subtractTent(option) {
      if (this.options[option].quantity > this.findInitialAmount(option)) {
        this.options[option].quantity -= 1;
        this.optionsToAdd[option].quantity -= 1;
        this.totalToAdd -= 1;
      }
    },

    addTent(option, tentQuantity) {
      if (this.optionsToAdd[option].quantity >= tentQuantity?.quantity) return;
      this.options[option].quantity += 1;
      this.optionsToAdd[option].quantity += 1;
      this.totalToAdd += 1;
    },

    addTents() {
      this.showModal = false;
    },

    async bookTents() {
      try {
        const res = await this.$apollo.mutate({
          mutation: bookTents,
          variables: {
            input: {
              reservationnumber: this.reservation.reservationnumber,
              toAdd: JSON.stringify(this.optionsToAdd),
            },
          },
        });
        if (res?.data?.BookTents?.status === 200) {
          window.location.href = res?.data?.BookTents?.paymentUrl;
        } else {
          this.showModal = false;
          this.errorMsg = res?.data?.BookTents?.errorMsg;
          this.showErrorModal = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
