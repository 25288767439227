<template>
  <div :class="classNames" class="indicator"></div>
</template>

<script>
export default {
  props: {
    classNames: String,
  },
};
</script>

<style scoped>
.indicator {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 1px solid transparent;
}
.indicator.good {
  border-color: #25d366 !important;
}
.indicator.warning {
  border-color: #c50a0a !important;
}

.indicator.round {
  border-radius: 50%;
}
.indicator.round.good {
  background-color: #25d366;
}
.indicator.round.warning {
  background-color: #c50a0a;
}
</style>
