<template>
  <div
    :style="
      $route.meta?.showNav &&
      $route?.path !== '/news' &&
      $route?.path !== '/table'
        ? 'padding-bottom: 70px;'
        : ''
    "
  >
    <router-view v-slot="{ Component, route }">
      <transition
        :name="route.meta.transition || ''"
        :mode="route.meta.mode || ''"
      >
        <component :is="Component" :key="route.fullPath" />
      </transition>
    </router-view>
    <navBar v-if="$route.meta?.showNav"></navBar>
  </div>
  <div
    v-if="showInstallMessage && !$store.getters.hideInstallMessage"
    style="width: 95%; left: 2.5%; bottom: 25px"
    class="iosPwaPopUp bg-gray-300 rounded-lg fixed w-full text-center text-sm py-3 px-4"
  >
    <div
      class="rounded-full absolute right-2 top-1"
      @click="closeInstallMessage()"
    >
      <font-awesome-icon :icon="['fa', 'xmark']" />
    </div>
    Instaleer de mijn teamweekender web app. Klik hier onder en klik vervolgens
    op 'Zet op beginscherm'.
  </div>
  <div
    v-if="updateExists"
    @click="refreshApp"
    class="fixed z-50 bg-btnBg px-2 py-4"
    style="bottom: 40px"
  >
    An update is available. Click to update
  </div>
  <div>
    <div
      ref="backdrop"
      class="modal-backdrop fade flex items-center justify-center"
    >
      <div @click="closeBackdrop" class="p-4">
        <font-awesome-icon
          class="absolute top-6 right-6 text-white text-2xl lg:text-4xl"
          :icon="['fa', 'xmark']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import update from '@/update.js';

import navBar from '@/components/partials/Nav.vue';

export default {
  data() {
    return {
      showInstallMessage: false,
    };
  },
  components: {
    navBar,
  },
  mixins: [update],
  mounted() {
    document.body.classList.add('bg-body');
    window.addEventListener('auth', (event) => {
      const isAuthenticated = event?.detail?.isAuthenticated;
      // compare strict, because variables could be undefined
      if (isAuthenticated === false) {
        this.$store.commit('setLoggedIn', false);
        this.$router.push({ name: 'Login' });
      }
    });

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.showInstallMessage = true;
    }
  },
  methods: {
    closeInstallMessage() {
      this.$store.commit('setHideInstallMessage', true);
    },

    closeBackdrop() {
      document.body.classList.remove('showModal');
    },
  },
};
</script>

<style scope>
.modal-backdrop.fade {
  pointer-events: none;
  opacity: 0;
}

.showModal .modal-backdrop.fade {
  pointer-events: auto;
  opacity: 0.8;
}

.modal-backdrop {
  z-index: 1071;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
</style>

<style>
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input {
  -webkit-user-select: text;
}

body {
  font-family: 'Staatliches', sans-serif !important;
}

p {
  font-family: Open Sans;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all;
  transition-delay: 0.5s;
  transition-duration: 1s;
}

.scale-slide-enter-from {
  right: -100vw;
}

.scale-slide-enter-to {
  right: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity;
  transition-delay: 0.3s;
  transition-duration: 0.8s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fadeQuick-enter-active,
.fadeQuick-leave-active {
  transition: opacity;
  transition-duration: 0.5s;
}

.fadeQuick-enter-from,
.fadeQuick-leave-to {
  opacity: 0;
}

.iosPwaPopUp:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: rgb(209 213 219);
  border-bottom: 0;
  margin-left: -20px;
  margin-bottom: -20px;
}
</style>
