<template>
  <div class="bg-primary pb-12 relative mb-7">
    <div class="w-11/12 mx-auto pt-4 text-white">
      <div class="absolute top-5 right-5 bg-btnBg p-4" @click="toQr">
        <font-awesome-icon
          class="
            absolute
            top-1/2
            left-1/2
            transform
            -translate-x-1/2 -translate-y-1/2
            text-2xl
          "
          :icon="['fas', 'qrcode']"
        />
      </div>
      <div class="user flex items-center mb-4">
        <div class="bg-white rounded-full relative p-8">
          <span
            class="
              text-xl text-black
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
          >
            {{ userName.initials }}
          </span>
        </div>
        <div class="pl-3">
          <b class="text-xl">{{ userName.fullName }}</b>
          <br />
          <span v-if="isMainBooker">Hoofdboeker</span>
          <span v-else>Teamlid</span>
        </div>
      </div>
      <h1 class="text-2xl pb-1" style="min-height: 38px">
        {{ teamName ? teamName : storeTeamName }}
      </h1>
      <p class="text-lg">
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'people-group']"
        />
        {{ numberofguests }} personen
      </p>
      <p class="text-lg" v-if="!boekinsStatus">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'ban']" />
        boeking geannuleerd
      </p>
      <p class="text-lg" v-else-if="boekinsStatus === 2">
        <font-awesome-icon style="width: 25px" :icon="['fas', 'hourglass']" />
        boeking in behandeling
      </p>
      <p class="text-lg" v-else>
        <font-awesome-icon
          style="width: 25px"
          :icon="['fas', 'circle-check']"
        />
        boeking bevestigd
      </p>
    </div>
    <div class="absolute h-12 w-full -bottom-6">
      <ul class="w-8/12 h-full mx-auto flex">
        <li
          class="w-2/6 border-2 border-primary flex items-center justify-center"
        >
          <router-link
            class="w-full h-full flex justify-center items-center bg-white"
            active-class="bg-btnBg text-white"
            :to="'payment'"
          >
            <b>betaling </b>
          </router-link>
        </li>
        <li
          class="
            w-2/6
            border-t-2 border-b-2 border-primary
            flex
            items-center
            justify-center
          "
        >
          <router-link
            class="w-full h-full flex justify-center items-center bg-white"
            active-class="bg-btnBg text-white"
            :to="'Team'"
          >
            <b> Team </b>
          </router-link>
        </li>
        <li
          class="w-2/6 border-2 border-primary flex items-center justify-center"
        >
          <router-link
            class="w-full h-full flex justify-center items-center bg-white"
            active-class="bg-btnBg text-white"
            :to="'Tenten'"
          >
            <b> tent </b>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { myUser } from '@/graphql/user/query.js';
import { getReservation } from '@/graphql/reservations/query.js';

export default {
  name: 'Reservation',

  props: {
    teamName: {
      type: [String, Boolean],
      default: false,
    },
  },

  apollo: {
    myUser() {
      return {
        query: myUser,
        networkPolicy: 'cache-first',
      };
    },
    reservation() {
      return {
        query: getReservation,
        variables() {
          return {
            reservationnumber: this.$route.params.reservationnumber,
          };
        },
        networkPolicy: 'cache-only',
        result({ data }) {
          if (data.reservation.voucher) {
            this.voucherInitialVal = data.reservation.voucher?.initialAmount;
            if (data.reservation.voucher?.amount > 0) {
              this.voucherPayment = true;
              this.voucherVal = data.reservation.voucher?.amount;
            }
          }
        },
      };
    },
  },
  methods: {
    toQr() {
      this.$router.push({
        name: 'QR',
        params: {
          reservationnumber: this.$route.params.reservationnumber,
          owner: JSON.stringify(this.myUser),
        },
      });
    },
  },
  computed: {
    storeTeamName() {
      return this.$store.getters.teamName;
    },
    userName() {
      return this.$store.getters.userName;
    },
    isMainBooker() {
      return (
        this.myUser?.guid == this.reservation?.team?.main_booker_user?.guid
      );
    },
    boekinsStatus() {
      return this.$store.getters.boekinsStatus;
    },
    numberofguests() {
      return this.$store.getters.numberofguests;
    },
  },
};
</script>
