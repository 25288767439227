<template>
  <div>
    <div
      class="md:min-h-full w-screen md:max-w-full min-h-screen max-w-screen bg-body flex flex-col"
    >
      <ReservationTopSection class="py-11" style="height: 15vh">
        <div class="text-center">
          <h2 class="text-secondaryHeading text-4xl">Media</h2>
        </div>
      </ReservationTopSection>

      <div v-if="!showImages">
        <div
          v-for="folder in folders"
          :key="folder.id"
          class="bg-white px-3 py-6 border-b-2 cursor-pointer"
          @click="getAssets(folder.id)"
        >
          <h2 class="text-2xl">{{ folder.name }}</h2>
        </div>
      </div>

      <div
        v-else
        :style="`height: calc(85vh - ${65}px)`"
        class="container px-4 mx-auto overflow-scroll"
        @scroll="handleScroll()"
      >
        <div
          class="ml-4 w-1/5 flex flex-row justify-center my-4 grid-toggle text-white active:bg-gray-600 text-xs rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 overflow-hidden"
        >
          <div
            @click="masonry = true"
            class="w-1/2 flex items-center justify-center px-3 py-2 active transition-all duration-150"
            :class="masonry ? 'bg-gray-500' : 'bg-gray-200'"
          >
            <font-awesome-icon
              class="text-xl"
              :icon="['far', 'grip-vertical']"
            />
          </div>
          <div
            @click="masonry = false"
            class="w-1/2 flex items-center justify-center px-3 py-2 singel transition-all duration-150"
            :class="masonry ? 'bg-gray-200' : 'bg-gray-500'"
          >
            <font-awesome-icon
              class="text-xl"
              :icon="['far', 'border-center-v']"
            />
          </div>
        </div>
        <!-- bind style (display) below to fix webkit render bug -->
        <div
          v-for="(images, page_index) in imagesArr"
          :style="masonry ? 'display: block' : 'display: inline-block'"
          :key="`page-${page_index}`"
        >
          <div v-if="images.length > 0" class="border-b-2 mb-4">
            <p class="text-gray-400">Pagina - {{ page_index + 1 }}</p>
          </div>
          <div
            class="masonry"
            :style="masonry ? 'columns: 2 153.333px;' : 'columns: 1 460px;'"
          >
            <div
              class="image relative"
              v-for="(image, image_index) in images"
              :key="`image-${image_index}`"
              @click="openImage(page_index, image_index)"
            >
              <img
                class="w-full"
                :src="transformImageSize(image.filename, '390x0')"
                alt=""
              />
            </div>
          </div>
        </div>
        <div ref="sensor"></div>
      </div>
    </div>
    <div
      v-if="imagesArr.length > 0 && diaImgIndex !== null"
      class="diaShow text-white h-screen"
    >
      <img
        width="460"
        class="w-11/12 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        :src="
          transformImageSize(
            imagesArr[diaPageIndex][diaImgIndex].filename,
            '460x0'
          )
        "
        alt=""
      />
      <div
        class="controls fixed bottom-16 w-11/12 left-1/2 transform -translate-x-1/2 items-center justify-between"
      >
        <div class="text-white py-4 w-1/3 text-left">
          <div v-if="diaImgIndex > 0 || diaPageIndex > 0" @click="prevSlide">
            vorige
          </div>
        </div>
        <a
          class="flex-1 text-center w-1/3"
          :href="imagesArr[diaPageIndex][diaImgIndex].filename"
          :download="`TW_2022-${diaPageIndex}-${diaImgIndex}`"
          target="_blank"
        >
          <div class="text-white py-4">Download</div>
        </a>
        <div class="text-white py-4 flex-1 text-right w-1/3" @click="nextSlide">
          volgende
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoryblokClient from 'storyblok-js-client';
import ReservationTopSection from '@/components/ReservationTopSection.vue';

export default {
  name: 'Media',
  components: {
    ReservationTopSection,
  },
  data() {
    return {
      storyblokOauthToken:
        '8AFS69M0Q3m1nQnGsrl6sgtt-95083-zBDzrbYxxPLNWt2mCBby',
      storyblokSpaceId: '152042',
      Storyblok: null,
      showImages: false,
      folders: [],
      imagesArr: [],
      masonry: true,
      diaPageIndex: null,
      diaImgIndex: null,
      pageNr: 1,
      currFolderId: null,
      fetching: false,
    };
  },
  mounted() {
    this.Storyblok = new StoryblokClient({
      oauthToken: this.storyblokOauthToken,
    });

    this.getFolders();
  },
  methods: {
    handleScroll() {
      if (
        this.$refs.sensor.getBoundingClientRect().bottom <
          window.innerHeight + window.innerHeight * 0.66 &&
        !this.fetching
      ) {
        this.fetching = true;
        this.pageNr++;
        this.getAssets(this.currFolderId);
      }
    },
    prevSlide() {
      if (this.diaImgIndex - 1 < 0) {
        if (this.diaPageIndex - 1 >= 0) {
          this.diaPageIndex--;
          this.diaImgIndex = this.imagesArr[this.diaPageIndex].length - 1;
        }
      } else {
        this.diaImgIndex--;
      }
    },
    async nextSlide() {
      if (this.diaImgIndex + 1 < this.imagesArr[this.diaPageIndex].length) {
        this.diaImgIndex++;
      } else if (this.diaPageIndex + 1 == this.imagesArr.length) {
        this.pageNr++;
        const result = await this.getAssets(this.currFolderId);

        if (result.data.assets.length > 0) {
          this.diaImgIndex = 0;
          this.diaPageIndex++;
        }
      } else if (this.diaPageIndex + 1 < this.imagesArr.length) {
        this.diaImgIndex = 0;
        this.diaPageIndex++;
      }
    },
    openImage(page_index, image_index) {
      this.diaPageIndex = page_index;
      this.diaImgIndex = image_index;
      document.body.classList.add('showModal');
    },
    transformImageSize(image, option) {
      if (!image) return '';
      if (!option) return '';

      const imageService = 'https://img2.storyblok.com/';
      const path = image.replace(
        'https://s3.amazonaws.com/a.storyblok.com/',
        ''
      );
      return imageService + option + '/filters:format(webp)/' + path;
    },

    async getFolders() {
      const self = this;

      try {
        await fetch(
          `${process.env.VUE_APP_GRAPHQL_API_PATH.replace(
            'graphql',
            'getStoryblokAssetsFolder'
          )}`,
          {
            method: 'POST',
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (result) {
            for (
              let index = 0;
              index < result?.data?.asset_folders.length;
              index++
            ) {
              const element = result?.data?.asset_folders[index];
              // @TODO Folder id from env
              if (element.parent_id == 164647) {
                self.folders.push(element);
              }
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async getAssets(id) {
      const self = this;
      this.currFolderId = id;

      try {
        const result = await fetch(
          `${process.env.VUE_APP_GRAPHQL_API_PATH.replace(
            'graphql',
            'getStoryblokAssets'
          )}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id, page: self.pageNr }),
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (result) {
            self.imagesArr.push(result.data.assets);

            if (result.data.assets.length > 0) self.fetching = false;

            self.showImages = true;
            return result;
          });

        return result;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.showModal .diaShow,
.showModal .diaShow img {
  display: block;
}

.showModal .diaShow .controls {
  display: flex;
}

.diaShow,
.diaShow img,
.diaShow .controls {
  display: none;
  z-index: 1071;
}

.masonry {
  max-width: 460px;
  margin: auto;
  column-gap: 15px;
}

/* .OneLine {
  max-width: 460px;
  margin: auto;
  column-gap: 15px;
} */

.image {
  margin: 0 0 10px 0;
  display: inline-block;
  width: 100%;
  break-inside: avoid;
  text-align: center;
  font-weight: bold;
}
</style>
