// import { from } from '@apollo/client';

import { ApolloClient, InMemoryCache } from '@apollo/client/core';

import { errorLink } from '@/apollo/errorLink';
import { httpLink } from '@/apollo/httpLink';

// const additiveLink = from([httpLink]);

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
});

export const apolloStoryBlokClient = new ApolloClient({
  uri: 'https://gapi.storyblok.com/v1/api',
  headers: {
    token: process.env.VUE_APP_STORYBLOK,
    version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
  },
  cache: new InMemoryCache(),
});
