import gql from 'graphql-tag';
export const getReservations = gql`
  query Reservations {
    reservations {
      id
      weekendID
      memberID
      teamID
      remarks
      internal_remarks
      secret
      reservationnumber
      tent_optionID
      housepreference2
      housepreference1
      housepreference3
      createdDateTime
      breakfast
      payment_status
      payment_DateTime
      discountID
      invitedBY
      deposit_feeID
      normal_feeID
      genderOfTeam
      depositDeadlineDateTime
      status
      voucher {
        guid
        initialAmount
        amount
      }
      voucherGuid
      numberofguests
      deposit_fee {
        amount
      }
      remnant_fee {
        amount
      }
      reservationPayments {
        reservationID
        memberID
        amount
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        status
      }
      team {
        name
        guid
        main_booker_user {
          name
        }
        members {
          id
          name
          main_booker
        }
      }
      event {
        locationName
        startsAt
        endsAt
      }
    }
  }
`;

export const getReservation = gql`
  query Reservation($reservationnumber: String!) {
    reservation(reservationnumber: $reservationnumber) {
      id
      weekendID
      memberID
      teamID
      reservationnumber
      tent_optionID
      payment_status
      payment_DateTime
      deposit_feeID
      normal_feeID
      genderOfTeam
      depositDeadlineDateTime
      status
      internal_remarks
      voucher {
        guid
        initialAmount
        amount
      }
      voucherGuid
      numberofguests
      deposit_fee {
        amount
      }
      remnant_fee {
        amount
      }
      reservationPayments {
        reservationID
        memberID
        amount
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        status
      }
      team {
        name
        guid
        changedName
        main_booker_user {
          name
          guid
        }
        members {
          id
          name
          main_booker
        }
      }
      tent {
        id
        tent_optionID
        quantity
        status
      }
    }
  }
`;

export const getUnAuthReservation = gql`
  query getUnAuthReservation($reservationnumber: String!) {
    reservationUnAuth(reservationnumber: $reservationnumber) {
      id
      numberofguests
      reservationnumber
      payment_status
      status
      reservationPayments {
        reservationID
        memberID
        amount
        nog
        type
        reservationnumber
        guid
        splitpayment
        voucherId
        status
      }
      voucher {
        guid
        initialAmount
        amount
      }
      deposit_fee {
        amount
      }
      remnant_fee {
        amount
      }
      team {
        name
      }
    }
  }
`;
