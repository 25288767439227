import gql from 'graphql-tag';

export const myUser = gql`
  query MyUser($jwt: String) {
    myUser(jwt: $jwt) {
      name
      surename
      guid
      email
      phone
      main_booker
    }
  }
`;

export const myQr = gql`
  query myQr(
    $reservationnumber: String!
    $userGuid: String
    $ticketGuid: String
  ) {
    myQr(
      reservationnumber: $reservationnumber
      userGuid: $userGuid
      ticketGuid: $ticketGuid
    )
  }
`;

export const ResetPassword = gql`
  query ResetPassword($email: String!) {
    ResetPassword(email: $email) {
      status
      statusMsg
    }
  }
`;
