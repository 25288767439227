import gql from 'graphql-tag';

export const getTeam = gql`
  query Team {
    team {
      name
      guid
      changedName
      main_booker_user {
        name
      }
      members {
        id
        guid
        name
        main_booker
      }
    }
  }
`;

export const getreservationMembers = gql`
  query reservationMembers($reservationnumber: String!) {
    reservationMembers(reservationnumber: $reservationnumber) {
      ticketcode_used
      ticketcode
      user {
        name
        guid
        main_booker
      }
    }
  }
`;
