const getters = {
  isLoggedIn(state) {
    return state.loggedIn;
  },
  userName(state) {
    return state.userName;
  },
  boekinsStatus(state) {
    return state.boekinsStatus;
  },
  numberofguests(state) {
    return state.numberofguests;
  },
  teamName(state) {
    return state.teamName;
  },
  changedName(state) {
    return state.changedName;
  },
  paymentsStatus(state) {
    return state.paymentsStatus;
  },
  hideInstallMessage(state) {
    return state.hideInstallMessage;
  },
  lastFetchedNotification(state) {
    return state.lastFetchedNotification;
  },
  newNoti(state) {
    return state.newNoti;
  },
};

export default getters;
