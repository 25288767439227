<template>
  <header class="w-full relative z-50" style="height: 58px">
    <div class="bg-primary px-3 pt-1 flex items-center absolute w-full h-full">
      <div class="logo w-auto h-full" style="margin: 0 auto">
        <img
          src="/img/icons/teamweekender-logo-small.svg"
          style="height: 100%; padding: 0.75em"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',

  methods: {
    toQr() {
      this.$router.push({
        name: 'QR',
        params: {
          reservationnumber: this.$route.params.reservationnumber,
        },
      });
    },
  },
};
</script>
