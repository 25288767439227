<template>
  <div>
    <Header />
    <!-- <section class="pt-14 flex items-center justify-center text-aditionalTxt"> -->
    <div
      class="bg-primary flex items-center justify-center"
      style="height: calc(100vh - 58px)"
    >
      <Card class="w-10/12 max-w-screen-sm overflow-hidden flex justify-center">
        <div
          class="w-full sm:w-10/12 flex flex-col justify-center items-center"
        >
          <div class="mb-6 text-center">
            <h2 class="text-primaryHeading text-3xl mb-3 font-bold">
              Kies je nieuw wachtwoord
            </h2>
            <p class="mb-2 text-lg">
              Vul hieronder een nieuw wachtwoord in. Hiermee kan jij vanaf nu
              inloggen bij Teamweekender.
            </p>
          </div>
          <form
            class="w-full text-left"
            ref="setPassForm"
            @submit.prevent="SubmitNewPassword($event)"
          >
            <basic-input
              v-model="password"
              :_uid="'password'"
              :type="'password'"
              :placeholder="'Nieuw wachtwoord'"
              :hasError="validation.firstError('password') ? true : false"
              :errMsg="validation.firstError('password')"
            />
            <basic-input
              v-model="passwordReference"
              :_uid="'password-2'"
              :placeholder="'Herhaal wachtwoord'"
              :type="'password'"
              :hasError="
                validation.firstError('passwordReference') ? true : false
              "
              :errMsg="validation.firstError('passwordReference')"
            />
            <button class="w-full" type="submit">
              <PrimaryButton class="inline-block">
                <div class="py-1 text-aditionalTxt text-xl font-bold">
                  Bevestigen
                </div>
              </PrimaryButton>
            </button>
          </form>
        </div>
      </Card>
    </div>
    <Modal class="flex align-center" v-if="showModal">
      <div class="w-11/12 mx-auto flex justify-center flex-col">
        <h2 class="text-black text-2xl mb-3 font-bold">
          {{ status === 200 ? 'Gelukt' : 'Er ging wat mis' }}
        </h2>
        <p class="mb-6">
          {{ msg }}
        </p>
        <div class="flex justify-center w-10/12 mx-auto">
          <PrimaryButton @click="$router.push({ name: 'Login' })">
            Sluiten
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// Graphql
import { SubmitNewPass } from '@/graphql/user/mutations.js';

// components
import Header from '@/components/partials/Header';
import BasicInput from '@/components/inputs/BasicInput.vue';
import PrimaryButton from '@/components/elements/PrimaryButton.vue';
import Card from '@/components/partials/Card.vue';
import Modal from '@/components/partials/Modal';

import SimpleVueValidator from 'simple-vue3-validator';

export default {
  name: 'SetNewPass',

  components: {
    Header,
    BasicInput,
    PrimaryButton,
    Card,
    Modal,
  },

  data() {
    return {
      password: '',
      passwordReference: '',
      showModal: false,
      errorMsg: '',
      status: null,
    };
  },

  mounted() {
    document.body.classList.add('bg-secondary');
  },

  beforeUnmount() {
    document.body.classList.remove('bg-secondary');
    document.body.classList.add('bg-gray-100');
  },

  validators: {
    password(value) {
      return SimpleVueValidator.Validator.value(value)
        .required()
        .minLength(6, 'Het wachtwoord is te kort');
    },
    'passwordReference, password': function (passwordReference, password) {
      if (this.submitted || this.validation.isTouched('passwordReference')) {
        return SimpleVueValidator.Validator.value(passwordReference)
          .required()
          .match(password, 'De wachtwoorden komen niet overeen');
      }
    },
  },

  methods: {
    validate() {
      return this.$validate().then(function (success) {
        return success;
      });
    },

    async SubmitNewPassword(e) {
      const validate = await this.validate();

      if (!validate) return;
      const password = this.password;
      e.preventDefault();
      try {
        const res = await this.$apollo.mutate({
          mutation: SubmitNewPass,
          variables: {
            input: {
              jwt: this.$route.params.jwt,
              password,
            },
          },
        });

        const response = res?.data?.SetNewPass;
        this.showModal = true;
        this.status = response?.status;
        this.msg = response?.statusMsg;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
