const mutations = {
  setLoggedIn(state, status) {
    state.loggedIn = status;
  },
  setUserName(state, user) {
    state.userName = {
      fullName: `${user.name} ${user.surename}`,
      initials: `${user.name[0]}${user.surename[0]}`,
    };
  },
  setBoekinsStatus(state, status) {
    state.boekinsStatus = status;
  },
  setNumberofguests(state, amount) {
    state.numberofguests = amount;
  },
  setChangedName(state, status) {
    state.changedName = status;
  },
  setTeamName(state, name) {
    state.teamName = name;
  },
  setPaymentStatus(state, status) {
    state.paymentsStatus = status;
  },
  setHideInstallMessage(state, status) {
    state.hideInstallMessage = status;
  },
  setLastFetchedNotification(state, id) {
    state.lastFetchedNotification = id;
  },
  setNewNoti(state, status) {
    state.newNoti = status;
  },
};

export default mutations;
