import gql from 'graphql-tag';

export const getTents = gql`
  query tents {
    tents {
      id
      quantity
      tent {
        id
        name
        price
        capacity_text
      }
    }
  }
`;
